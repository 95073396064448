import { FolderNode } from '@/graphql/codegen/graphql';
import { create } from 'zustand';

export interface RecentlyCreatedFolderNodeState {
  recentlyCreatedFolderNodes: FolderNode[];
  isFolderNodeCreationStateActive: boolean;
  newFolderNodeName: string;
}

export const useRecentlyCreatedFolderNodeStore = create<RecentlyCreatedFolderNodeState>(() => ({
  recentlyCreatedFolderNodes: [],
  isFolderNodeCreationStateActive: false,
  newFolderNodeName: '',
}));

export const startFolderNodeCreation = () => {
  useRecentlyCreatedFolderNodeStore.setState({ isFolderNodeCreationStateActive: true });
};

export const stopFolderNodeCreation = () => {
  useRecentlyCreatedFolderNodeStore.setState({
    isFolderNodeCreationStateActive: false,
    newFolderNodeName: '',
  });
};

export const setNewFolderNodeName = (newFolderNodeName: string) => {
  useRecentlyCreatedFolderNodeStore.setState({ newFolderNodeName });
};

export const addRecentlyCreatedFolderNode = (recentlyCreatedFolderNode: FolderNode) => {
  useRecentlyCreatedFolderNodeStore.setState(state => ({
    recentlyCreatedFolderNodes: [recentlyCreatedFolderNode, ...state.recentlyCreatedFolderNodes],
  }));
};

export const clearRecentlyCreatedFolderNodes = () => {
  useRecentlyCreatedFolderNodeStore.setState({ recentlyCreatedFolderNodes: [] });
};
