// @unocss-include

import { getFileExtensionType, getIconClassName } from '@/utils/getIconClassName';
import { DirectoryGraph, DirectoryNode } from '@skand/uploader';
import { UppyFile } from '@uppy/core';
import chunk from 'lodash/chunk';
import { basename, extname } from 'path-browserify';
import { useCreateProjectNodeFiles, useCreateProjectNodeFolder } from './mutations';

export type NodeFile = {
  type: 'file';
  file: UppyFile;
  fileId: null | string;
  fileImportRequestId: null | string;
};
export type NodeFolder = {
  type: 'folder';
  files: Set<string>;
  folders: Set<string>;
  firId: null | string;
};

export function assertIsFile(node: null | undefined | DirectoryNode): asserts node is NodeFile {
  if (node?.type !== 'file') throw new Error('node is not a file');
}

export function assertIsFolder(node: null | undefined | DirectoryNode): asserts node is NodeFolder {
  if (node?.type !== 'folder') throw new Error('node is not a folder');
}

export function assertIsString(examinee: unknown): asserts examinee is string {
  if (typeof examinee !== 'string') throw new Error('examinee is not a string');
}

export interface NodeItem {
  fileId: null | string;
  iconClass: string;
  indent: number;
  name: string;
  type: string;
  removeFile?: () => void;

  // progress
  bytesTotal: number;
  bytesUploaded: number;
  progress: number; // 0 - 1
  speed: number; // in bytes per second
  startedAt: null | number; // timestamp
  status: null | 'preparing' | 'prepared' | 'uploading' | 'uploaded' | 'failed';
  updatedAt: null | number; // timestamp, last time of progress was updated
}

export const buildTree = (
  graph: null | DirectoryGraph,
  origin: undefined | DirectoryNode,
  indent: number,
  nodes: NodeItem[],
  prevItems: NodeItem[],
) => {
  if (!origin) return nodes;
  assertIsFolder(origin);

  for (const path of origin.folders) {
    const node = graph?.get(path);
    assertIsFolder(node);

    nodes.push({
      fileId: null,
      iconClass: 'i-skand-files color-neutral-500 text-4',
      indent,
      name: basename(path),
      type: 'FOLDER',

      bytesTotal: 0,
      bytesUploaded: 0,
      progress: 0,
      speed: 0,
      startedAt: null,
      status: null,
      updatedAt: null,
    });

    buildTree(graph, node, indent + 1, nodes, prevItems);
  }

  for (const path of origin.files) {
    const node = graph?.get(path);
    assertIsFile(node);
    const prevNode = prevItems.find(item => item.fileId === node.file.id);

    const fileExtensionType = getFileExtensionType(node);
    nodes.push({
      fileId: node.file.id,
      iconClass: getIconClassName[fileExtensionType],
      indent,
      name: basename(path),
      type: extname(path).slice(1).toLocaleUpperCase(),

      bytesTotal: prevNode?.bytesTotal ?? 0,
      bytesUploaded: prevNode?.bytesUploaded ?? 0,
      progress: prevNode?.progress ?? 0,
      speed: prevNode?.speed ?? 0,
      startedAt: prevNode?.startedAt ?? null,
      status: prevNode?.status ?? 'preparing',
      updatedAt: prevNode?.updatedAt ?? null,
    });
  }
};

const CHUNK_THRESHOLD = 500;
export const createProjectNodes = async (
  graph: null | DirectoryGraph,
  origin: undefined | DirectoryNode,
  projectId: string,
  parentNodeId: null | string,
  createProjectNodeFolder: ReturnType<typeof useCreateProjectNodeFolder>,
  createProjectNodeFiles: ReturnType<typeof useCreateProjectNodeFiles>,
) => {
  if (!origin) return;
  assertIsFolder(origin);

  for (const path of origin.folders) {
    const node = graph?.get(path);
    assertIsFolder(node);

    const { createProjectFolderNode: childNode } = await createProjectNodeFolder({
      projectId,
      parentNodeId,
      name: basename(path),
    });

    assertIsString(childNode?.id);
    await createProjectNodes(
      graph,
      node,
      projectId,
      childNode.id,
      createProjectNodeFolder,
      createProjectNodeFiles,
    );
  }

  const fileIds: string[] = [];
  for (const path of origin.files) {
    const node = graph?.get(path);
    assertIsFile(node);
    assertIsString(node.fileId);
    fileIds.push(node.fileId);
  }

  const chunked = chunk(fileIds, CHUNK_THRESHOLD);
  for (const fileIds of chunked) {
    await createProjectNodeFiles({ projectId, parentNodeId, nodeIds: fileIds });
  }
};

export type ChunkOptions = 'auto' | '5M' | '50M' | '500M' | '5G';

export const calculateSpeed = (
  bytesUploaded: number,
  lastBytesUploaded: number,
  lastSpeed: number,
  lastUpdatedAt: number,
  now: number,
) => {
  const interval = (now - lastUpdatedAt) / 1000;
  if (interval === 0) return lastSpeed; // do not update speed if interval is 0
  const speed = (bytesUploaded - lastBytesUploaded) / interval;
  return speed;
};

export const calculateAverageSpeed = (
  bytesUploaded: number,
  lastSpeed: number,
  now: number,
  startedAt: number,
) => {
  const interval = (now - startedAt) / 1000;
  if (interval === 0) return lastSpeed; // do not update speed if interval is 0
  const speed = bytesUploaded / interval;
  return speed;
};

const displayError = (error: Error) => {
  if ('message' in error) return error.message;
  return error;
};

export const displayErrors = (errors: unknown) => {
  if (typeof errors === 'string' || errors instanceof String) return errors;
  if (errors instanceof Array) return displayError(errors[0]);
  else return displayError(errors as Error);
};
