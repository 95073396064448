import { cn } from '@skand/ui';
import { UploadSession, useUploadSession } from '@skand/uploader';
import { ChangeEventHandler, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadStore } from '../uploadStore';
import { UploadDetails } from './UploadDetails';
import { UploadOverview } from './UploadOverview';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export interface FileSectionProps {
  uploadSession: UploadSession;
}

export const FileSection = ({ uploadSession }: FileSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { getRootProps, getInputProps } = useDropzone({ onDrop: uploadSession.addFiles });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      e.preventDefault();
      const files = [...(e.target.files ?? [])];
      uploadSession.addFiles(files);
      e.target.value = ''; // reset files in dom
    },
    [uploadSession],
  );

  const showUploadDetails = useUploadStore(state => state.showUploadDetails);

  const { fileTreeRef } = useUploadSession(uploadSession);
  const numberOfFiles = fileTreeRef.current.numberOfFiles;
  const canAddFiles = numberOfFiles === 0;

  return (
    <div
      className={cn(
        'mt-6 b-1 b-neutral-400 rounded-2 b-solid px-3 flex flex-col flex-none overflow-auto',
        showUploadDetails && 'h-240px',
      )}
    >
      {numberOfFiles > 0 && <UploadOverview uploadSession={uploadSession} />}
      {numberOfFiles > 0 && showUploadDetails && <UploadDetails uploadSession={uploadSession} />}

      {canAddFiles && (
        <div
          className="b-1 b-dashed b-neutral-400 rounded-2 h-80px flex justify-center items-center my-3"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.DRAG_AND_DROP_FILES}
          {...getRootProps()}
        >
          <span className="typo-text-s color-neutral-500">Drag and drop files or&nbsp;</span>
          <label className="typo-link-s color-neutral-800 underline">
            browse files from your computer
            <input
              type="file"
              ref={inputRef}
              className="hidden"
              onChange={handleChange}
              multiple
              {...getInputProps()}
            />
          </label>
        </div>
      )}
    </div>
  );
};
