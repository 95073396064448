import formatBytes from '@/utils/formatBytes';
import { Button, Progress, cn } from '@skand/ui';
import { UploadSession, useUploadSession } from '@skand/uploader';
import {
  FAILURE_STAGES,
  PREPARED_STAGES,
  PREPARING_STAGES,
  UPLOADED_STAGES,
  UPLOADING_STAGES,
} from '../constants';
import { hideUploadDetails, showUploadDetails, useUploadStore } from '../uploadStore';

export interface UploadOverviewProps {
  uploadSession: UploadSession;
}

export const UploadOverview = ({ uploadSession }: UploadOverviewProps) => {
  const isShowingUploadDetails = useUploadStore(state => state.showUploadDetails);

  const { stage, uploadProgress } = useUploadSession(uploadSession);

  const filesTotal = uploadProgress.numberOfFilesTotal;
  const filesUploaded = uploadProgress.numberOfFilesUploaded;
  const progressOverall = uploadProgress.proportion;
  const speedMeta = formatBytes(uploadProgress.averageSpeed, 1);
  const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

  return (
    <div className={cn('flex items-center gap-2 justify-between my-3')}>
      <span className="flex-[2-2-auto] typo-text-s color-neutral-800">
        {filesUploaded} / {filesTotal} file(s) has been uploaded
      </span>

      <div className="flex-[1-1-auto] flex items-center">
        {FAILURE_STAGES.includes(stage) && (
          <span className="typo-text-s color-alert-400">Upload failed</span>
        )}

        {PREPARING_STAGES.includes(stage) && (
          <span className="typo-text-s color-neutral-500">Preparing...</span>
        )}
        {PREPARED_STAGES.includes(stage) && (
          <span className="typo-text-s-em color-success-400">Ready for upload</span>
        )}

        {UPLOADING_STAGES.includes(stage) && (
          <>
            <span className="typo-text-s color-neutral-500">Uploading</span>
            <Progress className="w-80px ml-2" progress={progressOverall} />
            <span className="typo-text-s color-neutral-500 ml-2">{speed}</span>
          </>
        )}

        {UPLOADED_STAGES.includes(stage) && (
          <>
            <span className="typo-text-s color-success-400">Upload complete</span>
            <span className="typo-text-s color-neutral-500 ml-2">{speed}</span>
          </>
        )}
      </div>

      <Button
        size="xs"
        onClick={isShowingUploadDetails ? hideUploadDetails : showUploadDetails}
        className="flex-[1-1-auto] flex gap-1 items-center gap-1 cursor-pointer"
      >
        {isShowingUploadDetails ? 'hide details' : 'show details'}
        <div
          className={cn(
            'i-skand-dropdown transition-transform',
            isShowingUploadDetails && 'transform-rotate-180',
          )}
        ></div>
      </Button>
    </div>
  );
};
