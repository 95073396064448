import * as PATHS from '@/constants/paths';
import { queryClient } from '@/graphql/client';
import { DataDownloadPage } from '@/pages/DataDownloadPage';
import { config } from '@/utils/split';
import { ToastRoot } from '@skand/ui';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { DataManagementPage } from '@/pages/FoldersPages/FoldersPage';

export const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SplitFactory config={config}>
        <BrowserRouter>
          <Switch>
            <Route path={PATHS.DATASETS_ZIP_DOWNLOAD}>
              <DataDownloadPage />
            </Route>

            <PrivateRoute fallback={PATHS.ROOT}>
              {/* To ensure backwards compatibility */}
              <Route path={PATHS.DATASETS_BETA_FOLDER_FILES}>
                <DataManagementPage />
              </Route>
              <Route exact path={PATHS.DATASETS_BETA_FOLDERS}>
                <DataManagementPage />
              </Route>

              <Route path={PATHS.DATASETS_FOLDER_FILES}>
                <DataManagementPage />
              </Route>
              <Route exact path={PATHS.DATASETS_FOLDERS}>
                <DataManagementPage />
              </Route>

              <Route exact path={PATHS.DATASETS_ROOT}>
                <Redirect to={PATHS.DATASETS_FOLDERS} />
              </Route>
            </PrivateRoute>
          </Switch>

          <ToastRoot className="z-1" size="l" />
        </BrowserRouter>
      </SplitFactory>
    </QueryClientProvider>
  );
};
