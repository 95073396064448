import {
  ABORT_MULTIPART_UPLOAD,
  COMPLETE_MULTIPART_UPLOAD,
  CREATE_DATA_IMPORT_REQUEST,
  CREATE_FILE_NODE_FOR_FOLDER_IMPORT_REQUEST,
  CREATE_FOLDER_IMPORT_REQUEST_FOR_DATA_IMPORT_REQUEST,
  CREATE_FOLDER_NODE_FOR_FOLDER_IMPORT_REQUEST,
  CREATE_IMAGE_THUMBNAILS_FOR_PERSISTED_IMAGES_BY_IDS,
  CREATE_MULTIPART_UPLOAD_FOR_FILE_IMPORT_REQUEST,
  CREATE_PRESIGNED_UPLOAD_PART_URL,
  TRACK_FILES_FOR_FOLDER_IMPORT_REQUEST,
  UPDATE_STATUS_FOR_DATA_IMPORT_REQUEST,
  UPDATE_STATUS_FOR_FOLDER_IMPORT_REQUEST,
} from '@/graphql/mutations';
import {
  COUNT_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID,
  FIND_DATA_IMPORT_REQUEST_BY_DATA_IMPORT_REQUEST_ID,
  FIND_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID,
} from '@/graphql/queries';
import { request } from '@/graphql/request';
import {
  AwsMultipartRequestService,
  ServerError,
  UploadSessionRequestService,
} from '@skand/uploader';

export const uploadSessionRequestService = new UploadSessionRequestService({
  createDataImportRequest: async variables => {
    const response = await request(CREATE_DATA_IMPORT_REQUEST, variables);
    const data = response.createDataImportRequest;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },

  findDataImportRequest: async variables => {
    const response = await request(FIND_DATA_IMPORT_REQUEST_BY_DATA_IMPORT_REQUEST_ID, variables);
    const rootFolderImportRequestId =
      response.findDataImportRequestByDataImportRequestId?.rootFolderImportRequestId;
    if (!rootFolderImportRequestId) throw new ServerError('server responded unexpected data');
    return { rootFolderImportRequestId };
  },

  createFolderImportRequests: async variables => {
    const response = await request(CREATE_FOLDER_IMPORT_REQUEST_FOR_DATA_IMPORT_REQUEST, variables);
    const data = response.createFolderImportRequestForDataImportRequest;
    if (!data || data.some(id => id === null))
      throw new ServerError('server responded unexpected data');
    return data as string[];
  },

  createFileImportRequests: async variables => {
    const response = await request(TRACK_FILES_FOR_FOLDER_IMPORT_REQUEST, variables);
    const data = response.trackFilesForFolderImportRequest;
    if (!data || data.some(id => id === null))
      throw new ServerError('server responded unexpected data');
    return data as string[];
  },

  createFolderNodes: async variables => {
    const response = await request(CREATE_FOLDER_NODE_FOR_FOLDER_IMPORT_REQUEST, variables);
    const data = response.createFolderNodeForFolderImportRequest;
    if (!data || data.some(id => id === null))
      throw new ServerError('server responded unexpected data');
    return data as string[];
  },

  createFileNodes: async variables => {
    const response = await request(CREATE_FILE_NODE_FOR_FOLDER_IMPORT_REQUEST, variables);
    const data = response.createFileNodeForFolderImportRequest;
    if (!data || data.some(id => id === null))
      throw new ServerError('server responded unexpected data');
    return data as string[];
  },

  updateDataImportRequestStatus: async variables => {
    await request(UPDATE_STATUS_FOR_DATA_IMPORT_REQUEST, variables);
  },
  updateFolderImportRequestStatus: async variables => {
    await request(UPDATE_STATUS_FOR_FOLDER_IMPORT_REQUEST, variables);
  },

  countFileImportRequests: async variables => {
    const response = await request(COUNT_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID, variables);
    const data = response.countFileImportRequestsByDataImportRequestId;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },
  findFileIds: async variables => {
    const response = await request(FIND_FILE_IMPORT_REQUESTS_BY_DATA_IMPORT_REQUEST_ID, variables);
    const data = response.findFileImportRequestsByDataImportRequestId;
    if (!data) throw new ServerError('server responded unexpected data');
    const fileIds = data.map(fileImportRequest => fileImportRequest?.fileId);
    if (fileIds.some(fileId => fileId === null || fileId === undefined))
      throw new ServerError('server responded unexpected data');
    return fileIds as string[];
  },
  createThumbnails: async variables => {
    const response = await request(CREATE_IMAGE_THUMBNAILS_FOR_PERSISTED_IMAGES_BY_IDS, variables);
    const data = response.createImageThumbnailsForPersistedImagesByIds;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
  },
});

export const awsMultipartRequestService = new AwsMultipartRequestService({
  abortMultipartUpload: async variables => {
    const response = await request({ document: ABORT_MULTIPART_UPLOAD, variables });
    const data = response.abortMultipartUpload;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },

  completeMultipartUpload: async (variables, signal) => {
    const response = await request({ document: COMPLETE_MULTIPART_UPLOAD, variables, signal });
    const data = response.completeMultipartUpload;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },

  createMultipartUpload: async (fileNode, signal) => {
    const response = await request({
      document: CREATE_MULTIPART_UPLOAD_FOR_FILE_IMPORT_REQUEST,
      variables: {
        dataImportRequestId: fileNode.dataImportRequestId as string,
        fileImportRequestId: fileNode.fileImportRequestId as string,
      },
      signal,
    });
    const data = response.createMultipartUploadForFileImportRequest;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    const { bucket, key, uploadId } = data;
    if (!bucket || !key || !uploadId) throw new ServerError('server responded unexpected data');
    return { bucket, key, uploadId };
  },

  signMultipartUploadPart: async (variables, signal) => {
    const response = await request({
      document: CREATE_PRESIGNED_UPLOAD_PART_URL,
      variables,
      signal,
    });
    const data = response.createPresignedUploadPartUrl;
    if (data === null || data === undefined)
      throw new ServerError('server responded unexpected data');
    return data;
  },
});
