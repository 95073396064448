import { useProjectParentNodeChain } from '@/hooks/useProjectParentNodeChain';
import { useMemo } from 'react';

export interface BreadcrumbProps {
  disabled: boolean;
  onChangeParentNodeId: (parentNodeId: null | string) => void;
  parentNodeId: null | string;
}

export const Breadcrumb = ({ disabled, onChangeParentNodeId, parentNodeId }: BreadcrumbProps) => {
  const { parentNodeChain } = useProjectParentNodeChain(parentNodeId ?? undefined);

  const nodes = useMemo(() => {
    if (!parentNodeChain || !parentNodeId) return [];
    return parentNodeChain.slice().reverse(); // slice() to avoid mutation
  }, [parentNodeId, parentNodeChain]);

  return (
    <p className="color-neutral-500 flex justify-start items-center overflow-x-auto overflow-y-hidden">
      <span
        className="typo-link-s cursor-pointer underline"
        onClick={() => !disabled && onChangeParentNodeId(null)}
      >
        Root folder
      </span>

      {nodes.map(item => {
        return (
          <span
            key={item?.id}
            className="typo-text-s cursor-pointer"
            onClick={() => !disabled && onChangeParentNodeId(item?.id as string)}
          >
            {' / '}
            <span className="hover:underline">{item?.name}</span>
          </span>
        );
      })}
    </p>
  );
};
