import axios from 'axios';

export const downloadBlob = (url: string, onProgress: (percent: number) => void) => {
  const controller = new AbortController();
  const promise = axios
    .get(url, {
      onDownloadProgress: progressEvent => onProgress(progressEvent.progress ?? 0),
      responseType: 'blob',
      signal: controller.signal,
    })
    .then(response => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      return blob;
    });

  return { controller, promise };
};

export const saveBlob = (blob: Blob, fileName?: string) => {
  const dom = document.createElement('a');
  dom.href = window.URL.createObjectURL(blob);
  dom.setAttribute('download', fileName ?? '');
  dom.click();
};
