export const VideoThumbnailIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="#726F74" />
      <path
        d="M14.2493 11.6805C14.4921 11.8262 14.492 12.178 14.2492 12.3237L7.19288 16.5558C6.94293 16.7057 6.62501 16.5256 6.62501 16.2342L6.625 7.76655C6.625 7.47504 6.94305 7.29501 7.19299 7.44503L14.2493 11.6805Z"
        stroke="#726F74"
        strokeWidth="1.25001"
      />
      <line x1="1" y1="4.5" x2="19" y2="4.5" stroke="#726F74" />
    </svg>
  );
};
