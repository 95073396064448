import { ActionType, ObjectType, SubjectType } from '@/constants/policy';
import {
  InputMaybe,
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
} from '@/graphql/codegen/graphql';
import { LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

interface UseListPermissionPoliciesWithAccountContextQueryProps {
  objectId: string | null;
  objectType: ObjectType | null;
  subjectId: string | null;
  subjectType: SubjectType | null;
  actionType: ActionType | null;
}

const getQueryKey = (args: UseListPermissionPoliciesWithAccountContextQueryProps) => [
  'LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT',
  { ...args },
];

export const useListPermissionPoliciesWithAccountContextQuery = ({
  objectId,
  objectType,
  subjectId,
  subjectType,
  actionType,
}: UseListPermissionPoliciesWithAccountContextQueryProps) => {
  const response = useQuery({
    enabled: !!objectId,
    queryFn: () =>
      request(LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT, {
        objectId: objectId ?? null,
        objectType: (objectType as InputMaybe<PolicyObjectTypeInput>) ?? null,
        subjectId: subjectId ?? null,
        subjectType: (subjectType as InputMaybe<PolicySubjectTypeInput>) ?? null,
        actionType: (actionType as InputMaybe<PolicyActionTypeInput>) ?? null,
      }),
    queryKey: getQueryKey({
      objectId,
      objectType,
      subjectId,
      subjectType,
      actionType,
    }),
    refetchOnMount: true,
  });

  const permissionPolicies = useMemo(() => {
    return response.data?.listPermissionPoliciesWithAccountContext || [];
  }, [response.data?.listPermissionPoliciesWithAccountContext]);

  return {
    response,
    permissionPolicies,
  };
};

useListPermissionPoliciesWithAccountContextQuery.getQueryKey = getQueryKey;
