// @unocss-include

import { NodeFile } from '@/pages/FoldersPages/FoldersPage/Upload/utils';
import { FileTreeNode } from '@skand/uploader';
import { extname } from 'path-browserify';

enum ImageExtensions {
  IMAGE = 'IMAGE',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  GIF = 'GIF',
  BMP = 'BMP',
  TIFF = 'TIFF',
  TIF = 'TIF',
  SVG = 'SVG',
}

enum VideoExtensions {
  VIDEOS = 'VIDEOS',
}

enum CommonVideoExtensions {
  MP4 = 'MP4',
  MPEG4 = 'MPEG-4',
  MOV = 'MOV',
  AVI = 'AVI',
  FLV = 'FLV',
  WMV = 'WMV',
  WEBM = 'WEBM',
  MKV = 'MKV',
}

export enum FolderIconExtensions {
  FOLDER = 'FOLDER',
}

enum ExistingSkandExtensions {
  POINTCLOUD = 'POINTCLOUD',
  MESH3D = 'MESH3D',
  OBJ = 'OBJ',
  BIM = 'BIM',
  ORTHO = 'ORTHO',
  IFC = 'IFC',
  DXF = 'DXF',
}

enum DMRealServeIconsExtensions {
  DOCX = 'DOCX',
  DWG = 'DWG',
  E57 = 'E57',
  FXL = 'FXL',
  LISCAD = 'LISCAD',
  MJF = 'MJF',
  PDF = 'PDF',
  PNG = 'PNG',
  PPTX = 'PPTX',
  RCP = 'RCP',
  RVT = 'RVT',
  XLSX = 'XLSX',
  ZIP = 'ZIP',
}

export const isImageExtension = (ext: string): boolean => {
  return Object.values(ImageExtensions).includes(ext as ImageExtensions);
};

export const isVideoExtension = (ext: string): boolean => {
  return Object.values(CommonVideoExtensions)
    .map(e => e.toLowerCase())
    .includes(ext.toLowerCase());
};

export const isPdfExtension = (ext: string): boolean => {
  return ext.toLowerCase() === 'pdf';
};

export const getFileExtensionType = (node: NodeFile) => {
  if (node?.file?.type?.includes('video')) {
    return 'VIDEOS';
  } else if (node?.file?.type?.includes('image')) {
    return 'IMAGE';
  }
  return node.file.extension.toUpperCase();
};

export const getIconClassName: Record<string, string> = new Proxy(
  {
    ...Object.fromEntries(Object.values(ImageExtensions).map(ext => [ext, 'i-skand-image'])),
    ...Object.fromEntries(Object.values(FolderIconExtensions).map(ext => [ext, 'i-skand-files'])),
    ...Object.fromEntries(
      Object.values(ExistingSkandExtensions).map(ext => [ext, `i-skand-${ext.toLowerCase()}`]),
    ),
    ...Object.fromEntries(
      Object.values(DMRealServeIconsExtensions).map(ext => [ext, `i-custom:${ext.toLowerCase()}`]),
    ),
    ...Object.fromEntries(Object.values(VideoExtensions).map(ext => [ext, `i-custom:videos`])),
  },
  {
    get: (target, name) => {
      const baseClass = typeof name === 'string' && name in target ? target[name] : 'i-skand-file';

      return `${baseClass} color-neutral-500 text-4`;
    },
  },
);

export const EXTENSION_TO_ICON_CLASS: Record<string, string> = {
  DXF: 'color-neutral-500 text-4 i-skand-dxf',
  IFC: 'color-neutral-500 text-4 i-skand-ifc',
  KML: 'color-neutral-500 text-4 i-skand-bim',
  LAS: 'color-neutral-500 text-4 i-skand-pointcloud',
  LAZ: 'color-neutral-500 text-4 i-skand-pointcloud',
  MTL: 'color-neutral-500 text-4 i-skand-obj',
  OBJ: 'color-neutral-500 text-4 i-skand-obj',
  TIF: 'color-neutral-500 text-4 i-skand-ortho',
  TIFF: 'color-neutral-500 text-4 i-skand-ortho',

  DOCX: 'color-neutral-500 text-4 i-custom:docx',
  DWG: 'color-neutral-500 text-4 i-custom:dwg',
  E57: 'color-neutral-500 text-4 i-custom:e57', // or i-skand-pointcloud
  FXL: 'color-neutral-500 text-4 i-custom:fxl',
  LISCAD: 'color-neutral-500 text-4 i-custom:liscad',
  MJF: 'color-neutral-500 text-4 i-custom:mjf',
  PDF: 'color-neutral-500 text-4 i-custom:pdf',
  PNG: 'color-neutral-500 text-4 i-custom:png',
  PPTX: 'color-neutral-500 text-4 i-custom:pptx',
  RCP: 'color-neutral-500 text-4 i-custom:rcp',
  RVT: 'color-neutral-500 text-4 i-custom:rvt',
  XLSX: 'color-neutral-500 text-4 i-custom:xlsx',
  ZIP: 'color-neutral-500 text-4  i-custom:zip', // or i-skand-mesh3d
};

export const getFileTreeNodeIconClass = (fileTreeNode: FileTreeNode) => {
  if (fileTreeNode.type === 'folder') return 'i-skand-files color-neutral-500 text-4';

  const { file } = fileTreeNode;
  if (file.type.includes('video')) return 'i-custom:videos color-neutral-500 text-4';
  if (file.type.includes('image')) return 'i-skand-image color-neutral-500 text-4';

  const extension = extname(file.name).slice(1).toUpperCase();
  const iconClass = EXTENSION_TO_ICON_CLASS[extension];
  if (iconClass) return iconClass;

  return 'i-skand-file color-neutral-500 text-4';
};
