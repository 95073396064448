import { DatasetState, Store } from './Store';
import { isEmpty } from '../empty';

/**
 * Fields in the URL that will be serialized.
 */
interface URLFields {
  shareLinkToken: DatasetState['shareLinkToken'];
}

export class URLStore implements Store {
  private state: Partial<URLFields>;
  private canUpdate: boolean;

  constructor() {
    this.state = {};
    this.canUpdate = true;

    const params = new URLSearchParams(window.location.search.slice(1));
    for (const [key, value] of params) {
      const field = key as keyof URLFields;
      const object = this.deserialize(field, value);
      if (!isEmpty(object)) {
        this.state = {
          ...this.state,
          [field]: object,
        };
      }
    }
  }

  /**
   * Refresh the URL with the current state.
   */
  public refreshURL() {
    const params = new URLSearchParams(window.location.search.slice(1));
    const prevString = params.toString();

    // Remove any keys that are no longer in the state
    for (const key of params.keys()) {
      if (!(key in this.state)) {
        params.delete(key);
      }
    }

    // Set new fields
    for (const field of Object.keys(this.state)) {
      const value = this.serialize(field as keyof URLFields);
      if (!isEmpty(value)) {
        params.set(field, value);
      }
    }

    // Only replace state (throttled) if something has changed
    const currString = params.toString();
    if (prevString !== currString && this.canUpdate) {
      history.replaceState(null, '', `?${currString}`);
      this.canUpdate = false;
      setTimeout(() => {
        this.canUpdate = true;
      }, 310);
    }
  }

  private serialize(field: keyof URLFields): string | null {
    const value = this.state[field];
    if (isEmpty(value)) {
      return null;
    }
    switch (field) {
      case 'shareLinkToken':
        return value as string;
      default:
        return null;
    }
  }

  private deserialize<T extends keyof URLFields>(field: T, value: string): URLFields[T] | null {
    try {
      switch (field) {
        case 'shareLinkToken':
          return value as URLFields[T];
        default:
          return null;
      }
    } catch (e) {
      return null;
    }
  }

  get<T extends keyof DatasetState>(field: T): DatasetState[T] | null {
    switch (field) {
      case 'shareLinkToken':
        return (this.state.shareLinkToken ?? null) as DatasetState[T] | null;
      default:
        return null;
    }
  }

  set<T extends keyof DatasetState>(field: T, value: DatasetState[T]): void {
    switch (field) {
      case 'shareLinkToken':
        this.state.shareLinkToken = value as DatasetState['shareLinkToken'];
        break;
    }
  }

  clear<T extends keyof DatasetState>(field: T): void {
    switch (field) {
      case 'shareLinkToken':
        delete this.state.shareLinkToken;
        break;
    }
  }
}
