import { queryClient } from '@/graphql/client';
import { CreateSystemFolderNodeInput, FolderNode } from '@/graphql/codegen/graphql';
import { CREATE_SYSTEM_FOLDER_NODE } from '@/graphql/mutations';

import { cn } from '@/utils/classname';
import { getIconClassName } from '@/utils/getIconClassName';
import { Button, CheckBox, Input } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { request } from '@/graphql/request';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  addRecentlyCreatedFolderNode,
  setNewFolderNodeName,
  stopFolderNodeCreation,
  useRecentlyCreatedFolderNodeStore,
} from '@/stores/recentlyCreatedFolderNodesStore';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export const CreateFolderRow = () => {
  const parentNodeId = useParams<{ id: string }>().id ?? null;

  const { isFolderNodeCreationStateActive, newFolderNodeName } = useRecentlyCreatedFolderNodeStore(
    state => state,
  );

  const { mutateAsync: createFolder } = useMutation({
    mutationFn: (input: CreateSystemFolderNodeInput) =>
      request(CREATE_SYSTEM_FOLDER_NODE, { input }),
    onMutate: async newFolder => {
      await queryClient.cancelQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      return { newFolder };
    },
  });

  const handleSave = useCallback(async () => {
    const { createSystemFolderNode: newlyCreatedFolder } = await createFolder({
      parentNodeId,
      name: newFolderNodeName,
    });

    addRecentlyCreatedFolderNode(newlyCreatedFolder as FolderNode);

    setNewFolderNodeName('');
    stopFolderNodeCreation();
  }, [createFolder, newFolderNodeName, parentNodeId]);

  if (!isFolderNodeCreationStateActive) return null;
  return (
    <tr
      className={cn(
        'px-2',
        'b-b-1 b-b-neutral-300 [&:not(:last-child)]:b-b-solid',
        'grid grid-cols-5 p-r-2 gap-x-2',
      )}
    >
      <td className={cn('flex items-stretch py-1 color-neutral-800 col-span-2')}>
        <div className="flex items-center flex-1 gap-2">
          <CheckBox disabled />
          <div className={getIconClassName['FOLDER']} />
          <div className="flex-1">
            <Input
              data-analytics-event-object={ANALYTICS_EVENT_OBJECT.NEW_FOLDER_NAME}
              placeholder="New folder name"
              onChange={setNewFolderNodeName}
              value={newFolderNodeName}
            />
          </div>
          <Button size="s" primary filled className="h-100%" onClick={handleSave} data-analytics-event-object={ANALYTICS_EVENT_OBJECT.SAVE_FOLDER_NAME}>
            Save
          </Button>
          <Button size="s" className="h-100%" onClick={stopFolderNodeCreation}>
            Cancel
          </Button>
        </div>
      </td>
      <td className={cn('flex items-center py-1 color-neutral-800')}></td>
      <td className={cn('flex items-center py-1 color-neutral-600')}>
        <span className="typo-text-s">FOLDER</span>
      </td>
      <td className={cn('flex items-center py-1 color-neutral-800')}>
        <div className="flex items-center justify-end gap-3 w-full ">
          <Button className="whitespace-nowrap" size="s" disabled>
            View details
          </Button>
          <Button className="whitespace-nowrap" primary size="s" disabled>
            Edit access
          </Button>
          <div className={cn('i-skand-more color-neutral-400')} />
        </div>
      </td>
    </tr>
  );
};
