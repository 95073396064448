import { GET_SIGNED_OBJECT_PREVIEW_URL } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';

const getQueryKey = (fileIds: null | string[]) => ['GET_SIGNED_OBJECT_PREVIEW_URL', fileIds];

export const usePreviewUrls = (fileIds: null | string[]) => {
  const response = useQuery({
    enabled: !!fileIds,
    queryFn: () => request(GET_SIGNED_OBJECT_PREVIEW_URL, { fileIds: fileIds }),
    queryKey: getQueryKey(fileIds),
    keepPreviousData: true,
  });

  return response.data?.filesByIds;
};

usePreviewUrls.getQueryKey = getQueryKey;
