export const NODES_TABLE_DEFAULT_PAGE_INDEX = 0;
export const NODES_TABLE_DEFAULT_PAGE_SIZE = 20;
export const NODES_TABLE_LARGE_PAGE_SIZE = 1000;
export const NODES_TABLE_PAGE_SIZE_10 = 10;

export const NODE_KIND = {
  FILE_NODE: 'FileNode',
  FOLDER_NODE: 'FolderNode',
  SYSTEM_NODE: 'SystemNode',
};

export type NodeKind = keyof typeof NODE_KIND;

export enum SearchOption {
  LOCAL = 'LOCAL',
  RECURSIVE = 'RECURSIVE',
}

export const MAX_TIME_MS_EXPIRED_ERROR_CODE = 50;
