import { create } from 'zustand';
import { calculateAverageSpeed, NodeItem } from './utils';

export interface UploadState {
  fileGraph: NodeItem[];
  filesTotal: number;
  filesUploaded: number;
  progressOverall: number;
  showUploadDetails: boolean;
  speedOverall: number;
  startedAt: null | number;
}

export const useUploadStore = create<UploadState>(() => ({
  fileGraph: [],
  filesTotal: 0,
  filesUploaded: 0,
  progressOverall: 0,
  showUploadDetails: false,
  speedOverall: 0,
  startedAt: null,
}));

export const setProgressOverall = (progress: number) =>
  useUploadStore.setState({ progressOverall: progress });

export const hideUploadDetails = () => useUploadStore.setState({ showUploadDetails: false });
export const showUploadDetails = () => useUploadStore.setState({ showUploadDetails: true });

export const incrementFilesUploaded = (count: number) =>
  useUploadStore.setState(state => {
    return {
      filesUploaded: state.filesUploaded + count,
    };
  });

export const resetUploadStore = () => {
  useUploadStore.setState({
    fileGraph: [],
    filesTotal: 0,
    filesUploaded: 0,
    progressOverall: 0,
    showUploadDetails: false,
    speedOverall: 0,
    startedAt: null,
  });
};

export const accumulateFiles = (files: unknown[]) => {
  useUploadStore.setState(state => {
    return {
      filesTotal: state.filesTotal + files.length,
    };
  });
};

export const setFileGraph = (updater: (prev: NodeItem[]) => NodeItem[]) => {
  useUploadStore.setState(state => {
    return {
      fileGraph: updater(state.fileGraph),
    };
  });
};

export const updateUploadProgress = (
  bytesTotal: number,
  bytesUploaded: number,
  fileId: string,
  progress: number,
) => {
  useUploadStore.setState(state => {
    const now = Date.now();
    const startedAt = state.startedAt ?? now;

    let bytesUploadedOverall = 0;
    const newItems: NodeItem[] = [];
    for (const item of state.fileGraph) {
      if (item.fileId !== fileId) {
        bytesUploadedOverall += item.bytesUploaded;
        newItems.push(item);
        continue;
      }

      bytesUploadedOverall += bytesUploaded;
      if (item.startedAt === null) {
        // start to upload
        newItems.push({
          ...item,
          bytesTotal,
          progress,
          startedAt: Date.now(),
          status: 'uploading',
          updatedAt: Date.now(),
        });
        continue;
      }

      // calculate speed
      const speed = calculateAverageSpeed(bytesUploaded, item.speed, now, item.startedAt as number);
      newItems.push({
        ...item,
        bytesTotal,
        bytesUploaded,
        progress,
        speed,
        status: 'uploading',
        updatedAt: now,
      });
    }

    const speedOverall = calculateAverageSpeed(
      bytesUploadedOverall,
      state.speedOverall,
      now,
      startedAt,
    );

    return {
      fileGraph: newItems,
      speedOverall,
      startedAt,
    };
  });
};
