import { NODE_KIND } from '@/constants/node';
import { UserV2, FileNode } from '@/graphql/codegen/graphql';
import { FoldersPageTableData } from '@/pages/FoldersPages/FoldersPage/useFoldersPageColumns';
import { SystemNode, useDataManagementPageStore } from '@/stores/dataManagementStore';
import { extractSystemNodeNameAndExtension } from '@/utils/systemNode';
import { useMemo } from 'react';

export const useTransformAndFilterSystemNodes = (systemNodes: SystemNode[]) => {
  const { serverConfirmedNameChanges, deletedSystemNodeIds } = useDataManagementPageStore(
    state => state,
  );
  const transformedAndFilteredSystemNodes = useMemo(
    () =>
      [...systemNodes].reduce((acc, systemNode) => {
        if (deletedSystemNodeIds[systemNode?.id as string]) {
          return acc;
        }
        const { name, extension } = extractSystemNodeNameAndExtension(systemNode);
        const data = {
          id: systemNode?.id as string,
          kind: systemNode?.kind as string,
          name: serverConfirmedNameChanges[systemNode?.id as string] ?? name,
          extension: extension as string,
          // size: '???',
          parentNodeId: systemNode?.parentNodeId as string | null,
          createdAt: systemNode?.createdAt as string,
          createdUser: systemNode?.createdUser as UserV2,
          lastDownloadedAt: systemNode?.lastDownloadedAt as string,
          lastDownloadedUser: systemNode?.lastDownloadedUser as UserV2,
          ...(systemNode?.kind === NODE_KIND.FILE_NODE && {
            fileId: (systemNode as FileNode).fileId as string,
          }),
        };
        return acc.concat(data);
      }, [] as FoldersPageTableData[]),
    [deletedSystemNodeIds, serverConfirmedNameChanges, systemNodes],
  );

  return { transformedAndFilteredSystemNodes };
};
