export interface TableDataPlaceholderProps {
  isFetching: boolean;
}

export const TableDataPlaceholder = ({ isFetching }: TableDataPlaceholderProps) => {
  return (
    <tr className="flex justify-center items-center h-4em">
      <td className="typo-text-s color-neutral-400">
        {isFetching ? 'Loading data...' : 'No data available'}
      </td>
    </tr>
  );
};
