import { useCallback, useRef } from 'react';
import { SearchOptionSelect } from './SearchOptionSelect';
import { Input } from '@skand/ui';
import { FindIcon } from '@/components/IconButton';
import {
  NODES_TABLE_DEFAULT_PAGE_INDEX,
  NODES_TABLE_DEFAULT_PAGE_SIZE,
  SearchOption,
} from '@/constants/node';
import { debounce } from 'lodash';
import {
  setPagination,
  setSearchKeyword,
  setSearchOption,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';

export const SearchFilesAndFolders = () => {
  const { searchOption, searchKeyword } = useDataManagementPageStore(state => state);

  const handleSearchWithDebounce = useRef(
    debounce(async keyword => {
      setSearchKeyword(keyword);
      setPagination(() => ({
        pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
        pageSize: NODES_TABLE_DEFAULT_PAGE_SIZE,
      }));
    }, 300),
  ).current;

  const handleSearchOptionChange = useCallback(
    (option: string) => {
      setSearchOption(option as SearchOption);
      setPagination(prevPagination => ({
        ...prevPagination,
        pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
      }));
    },
    [setPagination, setSearchOption],
  );

  return (
    <div className="flex gap-2">
      <div className="flex-1 flex gap-2">
        <div className="w-75">
          <Input
            placeholder="Search for files or folder names"
            value={searchKeyword}
            onChange={(value: string) => handleSearchWithDebounce(value)}
            tail={<FindIcon />}
          />
        </div>
        <SearchOptionSelect value={searchOption} onValueChange={handleSearchOptionChange} />
      </div>
    </div>
  );
};
