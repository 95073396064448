import axios from 'axios';
import { DownloadPart } from './DownloadPart';
import { DownloadState, DownloaderError, TDownloadState } from './types';

const _100MiB = 100 * 1024 * 1024;

export class Download {
  abortController = new AbortController();
  blob: Blob | null = null;
  connections: number;
  fileName: null | string;
  onComplete?: (blob: Blob) => void;
  onError?: (error: DownloaderError) => void;
  onProgress?: (progress: number) => void;
  parts: DownloadPart[] = [];
  partSize: number;
  progress = 0;
  ranges: number[][] = [];
  size: number;
  state: TDownloadState = DownloadState.STOPPED;
  url: string;

  constructor(
    url: string,
    size: number,
    options?: {
      connections?: number;
      partSize?: number;
      fileName?: string;
      onComplete?: (blob: Blob) => void;
      onError?: (error: DownloaderError) => void;
      onProgress?: (progress: number) => void;
    },
  ) {
    this.url = url;
    this.size = size;

    this.connections = options?.connections ?? 1;
    this.partSize = options?.partSize ?? _100MiB;
    this.fileName = options?.fileName ?? Download.fileName(url);
    this.onComplete = options?.onComplete;
    this.onError = options?.onError;
    this.onProgress = options?.onProgress;

    this.ranges = this.computeRanges();
    this.parts = this.constructParts();
  }

  computeRanges() {
    const ranges = [];

    let start = 0;
    let end = -1;

    while (end < this.size) {
      start = end + 1;
      end = Math.min(start + this.partSize - 1, this.size);
      ranges.push([start, end]);
    }

    return ranges;
  }

  constructParts() {
    const onProgress = () => {
      this.progress = this.parts.reduce((acc, part) => acc + part.loaded, 0) / this.size;
      this.onProgress?.(this.progress);
    };

    return this.ranges.map(
      range => new DownloadPart(this.url, range, onProgress, this.abortController.signal),
    );
  }

  start() {
    if (this.state === DownloadState.STOPPED) {
      this.abortController = new AbortController();
      for (const part of this.parts) {
        part.updateAbortSignal(this.abortController.signal);
      }
      this.download();
      this.state = DownloadState.DOWNLOADING;
    }
  }

  stop() {
    this.abortController.abort();
    this.state = DownloadState.STOPPED;
  }

  async download() {
    const tasks = [];
    const executing = new Set();

    for (const part of this.parts) {
      const task = Promise.resolve().then(() => part.download());
      tasks.push(task);
      executing.add(task);

      const clean = () => executing.delete(task);
      task
        .catch(reason => {
          this.state = DownloadState.FAILED;
          this.onError?.(reason);
        })
        .finally(clean);

      // When there are too many tasks executing, wait for one to finish before starting more
      if (executing.size >= this.connections) {
        await Promise.race(executing);
      }
    }

    const blobs = await Promise.all(tasks);

    for (const blob of blobs) {
      if (blob === null) return;
    }

    this.blob = new Blob(blobs as Blob[]);
    this.state = DownloadState.COMPLETED;
    this.onComplete?.(this.blob);
  }

  static fileName(url: string) {
    const urlObj = new URL(url);
    const name = urlObj.pathname.split('/').pop() ?? '';
    return name;
  }

  static async fileSize(url: string) {
    const response = await axios.head(url);
    const size = Number(response.headers['content-length']);

    if (!Number.isInteger(size)) {
      throw new Error(
        `Unknown content-length returned by server: ${response.headers['content-length']}`,
      );
    }

    return size;
  }
}
