import { useMemo } from 'react';

export const usePagination = <T>({
  elements,
  totalNumberOfElements,
  currentPageIndex,
  pageSize,
}: {
  elements: T[];
  totalNumberOfElements: number;
  currentPageIndex: number;
  pageSize: number;
}) => {
  const paginationResult = useMemo(() => {
    if (totalNumberOfElements === 0) {
      return {
        elementsForCurrentPage: [],
        totalNumberOfPages: 0,
      };
    }

    let elementsForCurrentPage = elements;

    if (elements.length > pageSize) {
      const offset = currentPageIndex * pageSize;
      elementsForCurrentPage = elements.slice(offset, offset + pageSize);
    }

    const totalNumberOfPages =
      totalNumberOfElements % pageSize === 0
        ? Math.floor(totalNumberOfElements / pageSize)
        : Math.floor(totalNumberOfElements / pageSize) + 1;

    return {
      elementsForCurrentPage,
      totalNumberOfPages,
    };
  }, [currentPageIndex, elements, totalNumberOfElements, pageSize]);

  return paginationResult;
};
