import { NodeItem } from '@/pages/FoldersPages/FoldersPage/Upload/utils';
import { cn } from '@/utils/classname';
import { flexRender, useReactTable } from '@tanstack/react-table';
import { HTMLAttributes, useState } from 'react';

export interface TableDenseProps<T> extends HTMLAttributes<HTMLTableElement> {
  table: ReturnType<typeof useReactTable<T>>;
}

export const TableDense = <T,>({
  table,
  className,
  headerRowClassName,
  tableBodyClassName,
  contentRowClassName,
  isScrollable = false,
  disabled = false,
  fileGraph,
  contentType,
}: {
  headerRowClassName?: string;
  tableBodyClassName?: string;
  contentRowClassName?: string;
  isScrollable?: boolean;
  disabled?: boolean;
  fileGraph?: NodeItem[];
  contentType?: 'FOLDER' | 'FILE';
} & TableDenseProps<T>) => {
  const [hoveredRange, setHoveredRange] = useState<[number, number] | null>(null);

  const handleFolderHover = (rowFile: NodeItem, index: number) => {
    if (fileGraph) {
      if (rowFile.type === 'FOLDER') {
        let endIndex = index + 1;
        while (endIndex < fileGraph.length && fileGraph[endIndex].indent > rowFile.indent) {
          endIndex++;
        }
        setHoveredRange([index, endIndex - 1]);
      } else {
        setHoveredRange([index, index]);
      }
    }
  };

  const handleHoverEnd = () => {
    setHoveredRange(null);
  };

  return (
    <table className={cn('w-full border-collapse table-auto', className)}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => {
          return (
            <tr
              className={cn('b-b-1 b-b-neutral-500 b-b-solid text-left', headerRowClassName)}
              key={headerGroup.id}
            >
              {headerGroup.headers.map(header => {
                const isSortable = header.column.getCanSort();
                const sort = header.column.getIsSorted();

                return (
                  <th
                    className={cn(
                      'pb-2 uppercase typo-button-xs',
                      disabled ? 'color-neutral-400' : 'color-neutral-800',
                    )}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      {isSortable && (
                        <div
                          className={cn(
                            'i-skand-dropdown ml-2',
                            sort === false && !disabled ? 'color-neutral-400' : 'color-neutral-800',
                            sort === 'asc' && 'rotate-180',
                          )}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>

      {table.getRowModel().rows.length === 0 && contentType === 'FOLDER' ? (
        <tbody>
          <tr>
            <td
              className="py-20"
              colSpan={table
                .getHeaderGroups()
                .reduce((acc, headerGroup) => acc + headerGroup.headers.length, 0)}
            >
              <div className="typo-text-s color-neutral-500 w-full flex justify-center items-center h-[desired_height]">
                <p>No folder in this project</p>
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody
          className={cn(tableBodyClassName, '')}
          style={{ scrollbarGutter: isScrollable ? 'stable' : 'auto' }}
        >
          {table.getRowModel().rows.map((row, index) => (
            <tr
              className={cn(
                contentRowClassName,
                hoveredRange && index === hoveredRange[0]
                  ? 'bg-neutral-300 '
                  : hoveredRange &&
                      index > hoveredRange[0] &&
                      index <= hoveredRange[1] &&
                      'bg-neutral-200',
              )}
              key={row.id}
              onMouseOver={() => {
                fileGraph && handleFolderHover(fileGraph[index], index);
              }}
              onMouseOut={handleHoverEnd}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  className={cn(
                    'px-2 py-2 typo-text-s ',
                    disabled ? 'color-neutral-400' : 'color-neutral-800',
                    '',
                  )}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};
