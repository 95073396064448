interface ExtensionCellProps {
  getValue: () => string;
}

export const ExtensionCell = ({ getValue }: ExtensionCellProps) => {
  const extensionName = getValue();

  return (
    <div className="w-full">
      <div>
        <span className="typo-text-s color-neutral-600">{extensionName}</span>
      </div>
    </div>
  );
};
