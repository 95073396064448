import { useZipRequestQuery } from '@/hooks/useZipRequest';
import { Downloader } from '@/pages/FoldersPages/FoldersPage/Downloader';
import { addUrl, setIsOpenDownloadDialog } from '@/stores/download';
import { cn } from '@/utils/classname';
import formatBytes from '@/utils/formatBytes';
import { DATASETS_ADVANCED_DOWNLOADER } from '@/utils/split';
import { Button, Skeleton, Toast } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useParams } from 'react-router-dom';

export const DataDownloadPage = () => {
  const { zipRequestId } = useParams<{ zipRequestId: string }>();
  const { zipRequest, response } = useZipRequestQuery(zipRequestId);
  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER]);
  const isAdvancedDownloaderEnabled = treatments[DATASETS_ADVANCED_DOWNLOADER].treatment === 'on';

  const { amount, unit } = formatBytes(zipRequest?.size);

  const handleContactSupportClick = () => {
    const emailAddress = 'support@skand.io';
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };

  const handleClickDownload = () => {
    if (!zipRequest?.signedGetObjectUrl) return;
    if (zipRequest?.size === undefined || zipRequest?.size === null) return;

    if (isAdvancedDownloaderEnabled) addUrl(zipRequest.signedGetObjectUrl, zipRequest.size);
    else window.open(zipRequest.signedGetObjectUrl, '_blank');
    setIsOpenDownloadDialog(true);
  };

  const isLinkExpired = new Date(zipRequest?.expiresAt as string) < new Date();

  if (response.isLoading) {
    return (
      <div
        className={cn(
          'flex justify-center items-center',
          'w-full h-full',
          'absolute top-0 left-0 right-0 bottom-0',
          'bg-neutral-100',
        )}
      >
        <div className="flex flex-col gap-4 w-fit">
          <Skeleton className="h-4 w-[600px]" />
          <Skeleton className="h-4 w-[580px]" />
          <Skeleton className="h-4 w-[480px]" />
          <Skeleton className="h-4 w-[480px]" />
        </div>
      </div>
    );
  }

  if (response.isError) {
    return (
      <div
        className={cn(
          'flex justify-center items-center ',
          ' w-full h-full',
          'absolute top-0 left-0 right-0 bottom-0',
          'bg-neutral-100',
        )}
      >
        <div className="flex flex-col gap-16 w-fit ">
          <div className={cn('flex gap-12  h-130px')}>
            <div>
              <svg
                width="130"
                height="130"
                viewBox="0 0 130 130"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M90.4959 68.1779L64.8426 93.8312L39.1892 68.1779"
                  stroke="#D8D6D9"
                  strokeWidth="8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M122.467 96.7922V116.596C122.467 120.568 119.217 123.818 115.245 123.818H14.4445C10.4722 123.818 7.22223 120.568 7.22223 116.596V96.7922"
                  stroke="#D8D6D9"
                  strokeWidth="8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M64.9436 92.7536V6.08691"
                  stroke="#D8D6D9"
                  strokeWidth="8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={cn(' flex flex-col justify-between items-start')}>
              <p className="typo-text-l color-neutral-800	">Something went wrong.</p>
              <p className="typo-text-m color-neutral-500	">Please check the URL and try again.</p>
              <p className="typo-text-m color-neutral-500	">
                For further information and assistance, please reach out to our support team at{' '}
                <span
                  className="underline typo-text-m-em cursor-pointer"
                  onClick={handleContactSupportClick}
                >
                  support@skand.io
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex justify-center items-center ',
        ' w-full h-full',
        'absolute top-0 left-0 right-0 bottom-0',
        'bg-neutral-100',
      )}
    >
      <div className="flex flex-col gap-16 w-fit ">
        <p className="typo-text-l color-neutral-800">
          {isLinkExpired ? 'This link has expired' : 'Your data export is ready to download!'}
        </p>
        <div className={cn('flex gap-12  h-130px')}>
          <div>
            <svg
              width="130"
              height="130"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M90.4959 68.1779L64.8426 93.8312L39.1892 68.1779"
                stroke="#D8D6D9"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M122.467 96.7922V116.596C122.467 120.568 119.217 123.818 115.245 123.818H14.4445C10.4722 123.818 7.22223 120.568 7.22223 116.596V96.7922"
                stroke="#D8D6D9"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M64.9436 92.7536V6.08691"
                stroke="#D8D6D9"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={cn(' flex flex-col justify-between items-start')}>
            <p className="typo-text-m color-neutral-500	">{`Data size: ${amount} ${unit}`}</p>
            <p className="typo-text-s color-neutral-500	">
              Please do not close this page while download is in progress.
            </p>
            <p className="typo-text-s color-neutral-500	">
              For further information and assistance, feel free to reach out to our support team at{' '}
              <span
                className="underline typo-text-s-em cursor-pointer"
                onClick={handleContactSupportClick}
              >
                support@skand.io
              </span>
            </p>
          </div>
        </div>

        {(zipRequest?.name || zipRequest?.note) && (
          <div>
            <p className="typo-text-m	color-neutral-500 mb-4">Notes for this dataset:</p>
            <div className="w-full h-38px bg-neutral-100 border-neutral-400 border border-solid rounded p-2 mb-6">
              <p className="typo-text-s color-neutral-600	">{zipRequest?.name}</p>
            </div>
            <div className="w-full h-120px bg-neutral-100 border-neutral-400 border border-solid rounded p-2">
              <p className="typo-text-s color-neutral-600	">{zipRequest?.note}</p>
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2">
          {isLinkExpired && (
            <Toast
              type="warn"
              message={
                <p className="typo-text-s color-neutral-800">
                  The link has expired. Please request a new link or contact{' '}
                  <span
                    className="underline typo-text-s-em cursor-pointer"
                    onClick={e => {
                      e.stopPropagation();
                      handleContactSupportClick();
                    }}
                  >
                    support@skand.io{' '}
                  </span>
                  if you believe this is an error.
                </p>
              }
            />
          )}
          <Button
            primary
            filled
            className="w-full"
            onClick={handleClickDownload}
            disabled={isLinkExpired}
          >
            Download
          </Button>
        </div>
      </div>

      <Downloader />
    </div>
  );
};
