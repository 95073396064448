import { ZIP_REQUEST_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (zipRequestId: string) => ['ZIP_REQUEST_BY_ID', zipRequestId];

export const useZipRequestQuery = (zipRequestId: string) => {
  const queryFunction = async (id: string) => request(ZIP_REQUEST_BY_ID, { zipRequestId: id });

  const response = useQuery({
    enabled: !!zipRequestId,
    queryFn: () => queryFunction(zipRequestId as string),
    queryKey: getQueryKey(zipRequestId as string),
    retry: false,
  });

  const zipRequest = useMemo(() => {
    return response.data?.structuredZipRequest;
  }, [response.data?.structuredZipRequest]);

  return { response, zipRequest };
};

useZipRequestQuery.getQueryKey = getQueryKey;
