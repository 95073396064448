import { NODE_KIND, NodeKind } from '@/constants/node';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import { useDataManagementPageStore } from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import { Button } from '@skand/ui';
import { Row, Table } from '@tanstack/react-table';
import { DetailsPopupButton } from './DetailsDropdownButton';
import { RowActions } from './RowActions';
import { FoldersPageTableData } from './useFoldersPageColumns';

export interface RowActionsContainer {
  row: Row<FoldersPageTableData>;
  table: Table<FoldersPageTableData>;
  onUpdateDetails: (
    kind: NodeKind,
    extension: string,
    updates: { systemNodeId: string; name: string },
  ) => void;
  onEditAccess: (systemNodeId: string) => void;
}

export const RowActionsContainer = ({
  row,
  table,
  onUpdateDetails,
  onEditAccess,
}: RowActionsContainer) => {
  const editingSystemNodeId = useDataManagementPageStore(state => state.editingSystemNodeId);
  const isOpenEditAccessDialog = useDataManagementPageStore(state => state.isOpenEditAccessDialog);

  const { userHasAdminPermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds: [row.original.id],
  });

  return (
    <div className="flex items-center justify-end gap-3 w-full ">
      <DetailsPopupButton nodeDetails={row.original} onUpdateDetails={onUpdateDetails} />
      <Button
        className={cn('whitespace-nowrap', {
          'hover:cursor-pointer': userHasAdminPermission,
        })}
        primary
        size="s"
        active={isOpenEditAccessDialog && row.original.id === editingSystemNodeId}
        disabled={row.original.kind === NODE_KIND.FILE_NODE || !userHasAdminPermission}
        onClick={() => onEditAccess(row.original.id)}
      >
        Edit access
      </Button>
      <RowActions
        row={row}
        selectedRows={
          table
            .getFilteredSelectedRowModel()
            .rows.map(row => row.original) as FoldersPageTableData[]
        }
      />
    </div>
  );
};
