import { PARENT_NODES_CHAIN_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (parentNodeId?: null | string) => [
  'PARENT_NODES_CHAIN_BY_PARENT_NODE_ID',
  parentNodeId,
];

export const useParentNodesChainByParentNodeIdQuery = (parentNodeId?: string) => {
  const response = useQuery({
    enabled: !!parentNodeId,
    queryFn: () =>
      request(PARENT_NODES_CHAIN_BY_PARENT_NODE_ID, { parentNodeId: parentNodeId as string }),
    queryKey: getQueryKey(parentNodeId as string),
    keepPreviousData: true,
  });

  const parentNodesChain = useMemo(() => {
    return response.data?.parentNodesChainByParentNodeId || [];
  }, [response.data?.parentNodesChainByParentNodeId]);

  return {
    response,
    parentNodesChain,
  };
};

useParentNodesChainByParentNodeIdQuery.getQueryKey = getQueryKey;
