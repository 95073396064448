import { queryClient } from '@/graphql/client';
import { DELETE_SYSTEM_NODE } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useMutation } from '@tanstack/react-query';
import { useEntitlements } from './useEntitlements';

export const useDeleteSystemNodeMutation = () => {
  const mutation = useMutation({
    mutationFn: (systemNodeId: string) => request(DELETE_SYSTEM_NODE, { systemNodeId }),
    onSuccess: () => {
      queryClient.invalidateQueries(['SEARCH_SYSTEM_NODES_RECURSIVE']);
      queryClient.invalidateQueries(useEntitlements.queryKey);
    },
  });

  return mutation;
};
