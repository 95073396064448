import { File } from '@/graphql/codegen/graphql';
import { displayErrors } from '@/pages/FoldersPages/FoldersPage/Upload/utils';
import { Downloader, DownloaderError } from '@/utils/Downloader';
import { Download } from '@/utils/Downloader/Download';
import { downloadBlob, saveBlob } from '@/utils/Downloader/utils';
import { toast } from '@skand/ui';
import { create } from 'zustand';

export interface DownloadItem {
  error?: DownloaderError;
  file?: File;
  legacy?: ReturnType<typeof downloadBlob>;
  name: string;
  progress: number;
  size: number;
  url: string;
}

export interface DownloadState {
  files: Record<string, DownloadItem>; // url -> DownloadItem
  isOpen: boolean;
  isRequestingDownload: boolean;
  downloadNoteModalState: {
    isOpen: boolean;
    actionFunction: (zipFileName: string, zipFileNote: string) => void;
    zipFileName: string;
    zipFileNote: string;
  };
  isUploadModalOpen: boolean;
}

export const useDownloadStore = create<DownloadState>(() => ({
  files: {},
  isOpen: false,
  isRequestingDownload: false,
  downloadNoteModalState: {
    isOpen: false,
    actionFunction: () => {
      return;
    },
    zipFileName: '',
    zipFileNote: '',
  },
  isUploadModalOpen: false,
}));

const downloader = new Downloader();

export const setIsUploadModalOpen = (isUploadModalOpen: boolean) =>
  useDownloadStore.setState(() => {
    return { isUploadModalOpen };
  });

export const setDownloadNoteModal = (downloadNoteModalState: {
  isOpen: boolean;
  actionFunction: (zipFileName: string, zipFileNote: string) => void;
  zipFileName: string;
  zipFileNote: string;
}) => {
  useDownloadStore.setState({ downloadNoteModalState });
};

export const setIsOpenDownloadDialog = (isOpen: boolean) =>
  useDownloadStore.setState(() => {
    return { isOpen };
  });

export const setIsRequestingDownload = (isRequestingDownload: boolean) => {
  useDownloadStore.setState(() => {
    return { isRequestingDownload };
  });
};

export const updateDownloadProgress = (url: string, progress: number) => {
  useDownloadStore.setState(state => {
    const downloadItem = state.files[url];
    if (!downloadItem) return {};

    return {
      files: {
        ...state.files,
        [url]: {
          ...state.files[url],
          progress,
        },
      },
    };
  });
};

export const updateDownloadError = (url: string, error: DownloaderError) => {
  useDownloadStore.setState(state => {
    const downloadItem = state.files[url];
    if (!downloadItem) return {};

    toast({
      type: 'warn',
      message: `${displayErrors(error)}`,
      clickToDismiss: true,
    });

    return {
      files: {
        ...state.files,
        [url]: {
          ...state.files[url],
          error,
        },
      },
    };
  });
};

export const removeFile = (url: string) => {
  useDownloadStore.setState(state => {
    const downloadItem = state.files[url];
    if (!downloadItem) return {};

    if (downloadItem.legacy) downloadItem.legacy.controller.abort();
    else {
      downloader.cancel(url);
      downloader.clear(`complete:${url}`);
      downloader.clear(`error:${url}`);
      downloader.clear(`progress:${url}`);
    }

    const files = { ...state.files };
    delete files[url];
    return { files };
  });
};

export const addFileLegacy = (file: File) => {
  const url = file.signedGetObjectDownloadUrl as string;
  const name = file.storage?.name as string;
  const size = file.storage?.size as number;

  const files = Object.values(useDownloadStore.getState().files);
  if (files.some(item => item.url === url || item.file?.id === file.id)) return;

  const handle = downloadBlob(url, progress => updateDownloadProgress(url, progress));
  handle.promise.then(blob => saveBlob(blob, name));
  handle.promise.catch(error => updateDownloadError(url, error));

  useDownloadStore.setState(state => {
    return {
      files: {
        ...state.files,
        [url]: {
          file,
          name,
          progress: 0,
          size,
          url,
          legacy: handle,
        },
      },
    };
  });
};

export const addFile = (file: File) => {
  const url = file.signedGetObjectDownloadUrl as string;
  const name = file.storage?.name as string;
  const size = file.storage?.size as number;

  const files = Object.values(useDownloadStore.getState().files);
  if (files.some(item => item.url === url || item.file?.id === file.id)) return;

  downloader.add(url, size, { connections: 4, partSize: 256 * 1024 * 1024 });
  downloader.on(`progress:${url}`, progress => updateDownloadProgress(url, progress));
  downloader.on(`error:${url}`, (error: DownloaderError) => updateDownloadError(url, error));
  downloader.on(`complete:${url}`, (blob: Blob) => {
    saveBlob(blob, name);
  });

  useDownloadStore.setState(state => {
    return {
      files: {
        ...state.files,
        [url]: {
          file,
          name,
          progress: 0,
          size,
          url,
        },
      },
    };
  });

  downloader.start(url);
};

export const downloadFiles = async (files: File[], isAdvanced = false) => {
  for (const file of files) {
    if (isAdvanced) addFile(file);
    else addFileLegacy(file);
  }
};

export const addUrl = async (url: string, size: number, fileName?: string) => {
  if (useDownloadStore.getState().files[url]) return;

  const name = fileName ?? Download.fileName(url);

  downloader.add(url, size, { connections: 4, partSize: 256 * 1024 * 1024 });
  downloader.on(`progress:${url}`, progress => updateDownloadProgress(url, progress));
  downloader.on(`error:${url}`, (error: DownloaderError) => updateDownloadError(url, error));
  downloader.on(`complete:${url}`, (blob: Blob) => {
    saveBlob(blob, name);
  });

  useDownloadStore.setState(state => {
    return {
      files: {
        ...state.files,
        [url]: {
          name,
          progress: 0,
          size,
          url,
        },
      },
    };
  });

  downloader.start(url);
};

export const retryDownload = (url: string) => {
  const downloadItem = useDownloadStore.getState().files[url];
  if (!downloadItem) return;

  if (downloadItem.legacy) {
    // cancel download
    downloadItem.legacy.controller.abort();
    // reset progress first
    useDownloadStore.setState(state => {
      return {
        files: {
          ...state.files,
          [url]: {
            ...state.files[url],
            error: undefined,
            progress: 0,
          },
        },
      };
    });

    // then update the handle, otherwise progress might be reset to 0 after download starts
    const handle = downloadBlob(url, progress => updateDownloadProgress(url, progress));
    handle.promise.then(blob => saveBlob(blob, downloadItem.name));
    handle.promise.catch(error => updateDownloadError(url, error));
    useDownloadStore.setState(state => {
      return {
        files: {
          ...state.files,
          [url]: {
            ...state.files[url],
            legacy: handle,
          },
        },
      };
    });
  } else {
    // cancel download
    downloader.cancel(url);
    downloader.clear(`complete:${url}`);
    downloader.clear(`error:${url}`);
    downloader.clear(`progress:${url}`);

    useDownloadStore.setState(state => {
      return {
        files: {
          ...state.files,
          [url]: {
            ...state.files[url],
            error: undefined,
            progress: 0,
          },
        },
      };
    });

    // start download
    downloader.add(url, downloadItem.size, { connections: 4, partSize: 256 * 1024 * 1024 });
    downloader.on(`progress:${url}`, progress => updateDownloadProgress(url, progress));
    downloader.on(`error:${url}`, (error: DownloaderError) => updateDownloadError(url, error));
    downloader.on(`complete:${url}`, (blob: Blob) => {
      saveBlob(blob, downloadItem.name);
    });

    downloader.start(url);
  }
};

export const cancelDownload = removeFile;
