import { FIND_SYSTEM_NODE_MOST_PERMISSIVE_ACTION_TYPE_FROM_PARENT_NODE_CHAIN_WITH_SYSTEM_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (systemNodeId: string) => [
  'FIND_SYSTEM_NODE_MOST_PERMISSIVE_ACTION_TYPE_FROM_PARENT_NODE_CHAIN_WITH_SYSTEM_NODE_ID',
  systemNodeId,
];

export const useFindSystemNodeMostPermissiveActionTypeBySystemNodeId = (
  systemNodeId: string | null,
) => {
  const response = useQuery({
    enabled: !!systemNodeId,
    queryFn: () =>
      request(
        FIND_SYSTEM_NODE_MOST_PERMISSIVE_ACTION_TYPE_FROM_PARENT_NODE_CHAIN_WITH_SYSTEM_NODE_ID,
        {
          systemNodeId: systemNodeId as string,
        },
      ),
    queryKey: getQueryKey(systemNodeId as string),
  });

  const actionType = useMemo(
    () =>
      response.data?.findSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId ??
      null,
    [response.data?.findSystemNodeMostPermissiveActionTypeFromParentNodeChainWithSystemNodeId],
  );

  return { response, actionType };
};

useFindSystemNodeMostPermissiveActionTypeBySystemNodeId.getQueryKey = getQueryKey;
