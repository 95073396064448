import { ProjectFolderNode } from '@/graphql/codegen/graphql';
import { FOLDER_PROJECT_NODE_BY_PROJECT_ID_AND_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const getQueryKey = (projectId?: null | string, parentNodeId?: null | string) => [
  'FOLDER_PROJECT_NODE_BY_PROJECT_ID_AND_PARENT_NODE_ID',
  projectId,
  parentNodeId,
];

export const useProjectFolderNodes = (
  projectId: null | undefined | string,
  parentNodeId: null | string,
) => {
  const response = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(FOLDER_PROJECT_NODE_BY_PROJECT_ID_AND_PARENT_NODE_ID, {
        projectId: projectId as string,
        parentNodeId,
      }),
    queryKey: getQueryKey(projectId, parentNodeId),
  });

  const projectNodes = useMemo(() => {
    return (
      response.data?.folderProjectNodesOfAccountByParentNodeId?.filter(
        (item): item is NonNullable<ProjectFolderNode> => !!item?.id,
      ) ?? []
    );
  }, [response.data?.folderProjectNodesOfAccountByParentNodeId]);

  return { response, projectNodes };
};

useProjectFolderNodes.getQueryKey = getQueryKey;
