import { LIST_ACTION_TYPES_BY_OBJECT_TYPE } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isDefined } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (objectType: string) => ['LIST_ACTION_TYPES_BY_OBJECT_TYPE', objectType];

export const useListActionTypesByObjectTypeQuery = (objectType: string) => {
  const { refetch: fetchActionTypesByObjectType } = useQuery({
    enabled: false,
    queryFn: () => request(LIST_ACTION_TYPES_BY_OBJECT_TYPE, { objectType }),
    queryKey: getQueryKey(objectType),
    keepPreviousData: true,
  });

  return useCallback(async () => {
    const response = await fetchActionTypesByObjectType();

    const actionTypes =
      response.data?.listActionTypesByObjectType?.filter(actionType => isDefined(actionType)) ?? [];

    return actionTypes;
  }, [fetchActionTypesByObjectType]);
};

useListActionTypesByObjectTypeQuery.queryKey = getQueryKey;
