import { CircularLoadingIndicator } from '@/components/CircularLoadingIndicator';
import { FindIcon } from '@/components/IconButton';
import { TableDataPlaceholder } from '@/components/TableDataPlaceholder';
import {
  NODES_TABLE_DEFAULT_PAGE_INDEX,
  NODES_TABLE_PAGE_SIZE_10,
  NODE_KIND,
  NodeKind,
} from '@/constants/node';
import { queryClient } from '@/graphql/client';
import {
  UpdateSystemNodesPositionMutation,
  UpdateSystemNodesPositionMutationVariables,
} from '@/graphql/codegen/graphql';
import { UPDATE_SYSTEM_NODES_POSITION } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useListSystemNodesByParentNodeIdQuery } from '@/hooks/useListSystemNodesByParentNodeIdQuery';
import {
  clearRowSelection,
  setEditingMultiplSystemNodeId,
  setEditingSystemNodeId,
  setIsOpenMoveToDialog,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, Input } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumbs } from '../FoldersPage/Breadcrumb';
import { Pagination } from '../FoldersPage/Pagination';
import { clearRecentlyCreatedFolderNodes } from '@/stores/recentlyCreatedFolderNodesStore';

type MoveToTableData = {
  id: string;
  name: string;
  kind: NodeKind;
  createdAt: string;
};

const columnHelper = createColumnHelper<MoveToTableData>();

export const MoveToDialog = () => {
  const { parentNodeId: initialParentNodeId } = useDataManagementPageStore(state => state);

  const isOpenMoveToDialog = useDataManagementPageStore(state => state.isOpenMoveToDialog);
  const editingSystemNodeId = useDataManagementPageStore(state => state.editingSystemNodeId);
  const editingMultipleSystemNodeId = useDataManagementPageStore(
    state => state.editingMultipleSystemNodeId,
  );

  const [parentNodeId, setParentNodeId] = useState<null | string>(initialParentNodeId);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
    pageSize: NODES_TABLE_PAGE_SIZE_10,
  });

  useEffect(() => {
    setParentNodeId(initialParentNodeId);
  }, [initialParentNodeId]);

  const {
    systemNodes: systemNodesQueryData,
    totalNumberOfPages,
    response: { isFetching: isFetchingSystemNodes },
  } = useListSystemNodesByParentNodeIdQuery(
    {
      parentNodeId: parentNodeId ?? null,
      pageSize,
      pageIndex,
      searchTerm: searchKeyword,
    },
    { keepPreviousData: true },
  );

  const handleSearchWithDebounce = useRef(
    debounce(async keyword => {
      setSearchKeyword(keyword);
    }, 300),
  ).current;

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const handleNavigateToFolderNode = useCallback((folderNodeId?: string) => {
    setParentNodeId(folderNodeId ?? null);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
    setSearchKeyword('');
    setRowSelection({});
  }, []);

  const handleClose = useCallback(() => {
    setEditingSystemNodeId(null);
    setIsOpenMoveToDialog(false);
    setPagination({
      pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
      pageSize: NODES_TABLE_PAGE_SIZE_10,
    });
    setSearchKeyword('');
    setRowSelection({});
  }, []);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpenMoveToDialog(open);

      if (!open) {
        handleClose();
      }
    },
    [handleClose],
  );

  // TODO-JONEL: Move into a hook
  const updateSystemNodesPosition = useMutation<
    UpdateSystemNodesPositionMutation,
    unknown,
    UpdateSystemNodesPositionMutationVariables,
    unknown
  >({
    mutationFn: ({ systemNodeIds, parentSystemNodeId }) =>
      request(UPDATE_SYSTEM_NODES_POSITION, {
        systemNodeIds,
        parentSystemNodeId,
      }),
  });

  useEffect(() => {
    if (isOpenMoveToDialog) {
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      clearRecentlyCreatedFolderNodes();
    }
  }, [isOpenMoveToDialog]);

  const handleMoveFilesAndFoldersSuccess = useCallback(() => {
    clearRowSelection();
    setEditingMultiplSystemNodeId([]);
    setEditingSystemNodeId(null);
    clearRecentlyCreatedFolderNodes();
    queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
    queryClient.invalidateQueries(
      useListSystemNodesByParentNodeIdQuery.getQueryKey(
        parentNodeId ?? null,
        pageSize,
        pageIndex,
        searchKeyword,
      ),
    );
  }, [pageIndex, pageSize, parentNodeId, searchKeyword]);

  const handleMoveSystemNode = useCallback(async () => {
    const newParentNodeId = Object.keys(rowSelection)[0];

    const systemNodesToBeMoved = [editingSystemNodeId, ...editingMultipleSystemNodeId].filter(
      Boolean,
    ) as string[];

    await updateSystemNodesPosition.mutateAsync({
      systemNodeIds: systemNodesToBeMoved,
      parentSystemNodeId: newParentNodeId,
    });

    handleMoveFilesAndFoldersSuccess();

    handleClose();

    // TODO-JONEL: Can we just mutate the cached state instead?
    // TODO-JONEL: Invalidate query related to system node (Query from Main Folders Page and from Move file dialog)
    // TODO-JONEL: Force re-query to avoid stale state

    // TODO-JONEL: Fix stale state for systemNodesQueryData in this component (Better mark all useListSystemNodesByParentNodeIdQuery query stale)
  }, [
    editingMultipleSystemNodeId,
    editingSystemNodeId,
    handleClose,
    handleMoveFilesAndFoldersSuccess,
    rowSelection,
    updateSystemNodesPosition,
  ]);

  const tableData: MoveToTableData[] = useMemo(
    () =>
      systemNodesQueryData.map(systemNode => ({
        id: systemNode?.id as string,
        name: systemNode?.name as string,
        kind: systemNode?.kind as NodeKind,
        createdAt: systemNode?.createdAt as string,
      })),
    [systemNodesQueryData],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'name',
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),

      columnHelper.accessor('createdAt', {
        header: 'date created',
        cell: ({ getValue }) => <span>{new Date(getValue()).toLocaleString()}</span>,
      }),
    ],
    [],
  );

  const table = useReactTable({
    columns,
    data: tableData,
    state: { sorting, rowSelection, pagination },
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    getRowId: row => row.id as string,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    pageCount: totalNumberOfPages,
    onPaginationChange: setPagination,
    manualPagination: true,
  });
  return (
    <Dialog.Root onOpenChange={handleOpenChange} open={isOpenMoveToDialog}>
      <Dialog.Portal>
        <div
          className={cn(
            'fixed left-0 top-0 z-1 h-full w-full flex items-center justify-center',
            ' bg-black bg-opacity-30',
          )}
        >
          <Dialog.Content
            className={cn(
              'bg-neutral-100',
              'fixed',
              'flex-col',
              'flex',
              'inset-y-12',
              'p-6',
              'rounded-2',
              'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
              'left-50% transform-translate-x--50%',
              'w-640px',
              'mt-auto mb-auto h-70vh',
              'z-10',
            )}
          >
            <Dialog.Title>
              <div className="flex justify-between items-center">
                <h1 className="color-neutral-800 typo-text-l">Move file to new location</h1>
              </div>
            </Dialog.Title>

            <div className="mt-6 mb-2 color-neutral-800 typo-text-m">Available folders</div>
            <div className="flex gap-2">
              <Breadcrumbs
                parentNodeId={parentNodeId ?? undefined}
                onNavigate={handleNavigateToFolderNode}
              />
              {isFetchingSystemNodes && <CircularLoadingIndicator />}
            </div>

            <div className={cn('b-1 b-neutral-400 rounded-1 b-solid p-x-3 p-t-3', 'mt-3 flex-1')}>
              <div className="flex gap-3">
                <div className="flex-1">
                  <Input
                    placeholder="Search for file or folder name"
                    value={searchKeyword}
                    onChange={value => handleSearchWithDebounce(value)}
                    tail={<FindIcon />}
                  />
                </div>
              </div>

              <table className="mt-3 w-full border-collapse">
                <thead>
                  {table.getHeaderGroups().map(headerGroup => {
                    return (
                      <tr
                        className={cn(
                          'b-b-1 b-b-neutral-500 b-b-solid text-left px-2',
                          'grid grid-cols-2 p-r-4',
                        )}
                        key={headerGroup.id}
                      >
                        {headerGroup.headers.map(header => {
                          const isSortable = header.column.getCanSort();
                          const sort = header.column.getIsSorted();

                          return (
                            <th
                              className={cn(
                                'py-3 text-left uppercase color-neutral-800 typo-button-xs',
                                {
                                  'hover:cursor-pointer': isSortable,
                                },
                              )}
                              onClick={header.column.getToggleSortingHandler()}
                              key={`${header.id}-${header.index}`}
                            >
                              <div className="flex items-center">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}

                                {isSortable && (
                                  <div
                                    className={cn(
                                      'i-skand-dropdown ml-2',
                                      'hover:cursor-pointer',
                                      sort === false ? 'color-neutral-400' : 'color-neutral-800',
                                      sort === 'asc' && 'rotate-180',
                                    )}
                                  />
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody
                  className={cn(
                    'grid h-[calc(70vh-364px)] overflow-y-auto auto-rows-max m-y-1',
                    'scrollbar scrollbar-rounded',
                  )}
                  style={{ scrollbarGutter: 'stable' }}
                >
                  {!isFetchingSystemNodes && table.getRowModel().rows.length > 0 ? (
                    table.getRowModel().rows.map(row => {
                      const isFolderNode = row.original.kind === NODE_KIND.FOLDER_NODE;
                      const enableAction = isFolderNode && row.original.id !== editingSystemNodeId;

                      return (
                        <tr
                          key={row.id}
                          className={cn('grid grid-cols-2 px-2', 'typo-text-s', {
                            'bg-neutral-200 rounded-1': row.getIsSelected(),
                            '[&_*]:hover:cursor-pointer': enableAction,
                            '[&_*]:color-neutral-400': !enableAction,
                          })}
                          onClick={enableAction ? row.getToggleSelectedHandler() : undefined}
                          onDoubleClick={
                            enableAction
                              ? () => handleNavigateToFolderNode(row.original.id)
                              : undefined
                          }
                        >
                          {row.getVisibleCells().map(cell => (
                            <td className={'pt-2 pb-2 color-neutral-800'} key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <TableDataPlaceholder isFetching={isFetchingSystemNodes} />
                  )}
                </tbody>
              </table>

              <div className="flex justify-center b-t-1 b-t-neutral-300 b-t-solid p-y-3">
                <Pagination
                  currentPage={table.getState().pagination.pageIndex + 1}
                  totalPages={table.getPageCount()}
                  onPageChange={newPage => table.setPageIndex(newPage - 1)}
                  isNextPageDisabled={!table.getCanNextPage()}
                  isPreviewPageDisabled={!table.getCanPreviousPage()}
                />
              </div>
            </div>

            <div className="mt-6 flex gap-3 justify-end">
              <Dialog.Close asChild>
                <Button
                  className={cn('hover:cursor-pointer', 'flex-1')}
                  onClick={() => handleClose()}
                  size="s"
                >
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                className={cn('hover:cursor-pointer', 'flex-1')}
                filled
                onClick={() => handleMoveSystemNode()}
                primary
                disabled={Object.keys(rowSelection).length === 0}
                size="s"
              >
                Move file
              </Button>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
