import { PARENT_PROJECT_NODES_CHAIN_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (parentNodeId?: string) => [
  'PARENT_PROJECT_NODES_CHAIN_BY_PARENT_NODE_ID',
  parentNodeId,
];

export const useProjectParentNodeChain = (parentNodeId?: string) => {
  const response = useQuery({
    enabled: !!parentNodeId,
    queryFn: () =>
      request(PARENT_PROJECT_NODES_CHAIN_BY_PARENT_NODE_ID, {
        parentNodeId: parentNodeId as string,
      }),
    queryKey: getQueryKey(parentNodeId),
    keepPreviousData: true,
  });

  const parentNodeChain = useMemo(() => {
    return response.data?.parentProjectNodesChainByParentNodeId;
  }, [response.data?.parentProjectNodesChainByParentNodeId]);

  return {
    response,
    parentNodeChain,
  };
};

useProjectParentNodeChain.getQueryKey = getQueryKey;
