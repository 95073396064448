export const LOG_IN = '/logIn';
export const ROOT = '/';

export const DATASETS_ROOT = '/datasets';

export const DATASETS_FOLDERS = `${DATASETS_ROOT}/folders`;
export const DATASETS_FOLDER_FILES = `${DATASETS_FOLDERS}/:id/files`;
export const DATASETS_ZIP_DOWNLOAD = `${DATASETS_ROOT}/download/zips/:zipRequestId`;

// To ensure backwards compatibility
export const DATASETS_BETA_ROOT = `/beta${DATASETS_ROOT}`;
export const DATASETS_BETA_FOLDERS = `${DATASETS_BETA_ROOT}/folders`;
export const DATASETS_BETA_FOLDER_FILES = `${DATASETS_BETA_FOLDERS}/:id/files`;
