import { PROJECT_NODES_BY_IDS } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const getQueryKey = (projectNodeId?: string) => ['PROJECT_NODES_BY_IDS', projectNodeId];

export const useProjectNode = (projectNodeId?: string) => {
  const response = useQuery({
    enabled: !!projectNodeId,
    queryFn: () => request(PROJECT_NODES_BY_IDS, { projectNodeIds: [projectNodeId as string] }),
    queryKey: getQueryKey(projectNodeId),
  });

  const projectNode = useMemo(() => {
    return response.data?.projectNodesByIds?.at(0);
  }, [response.data?.projectNodesByIds]);

  return { response, projectNode };
};

useProjectNode.getQueryKey = getQueryKey;
