import { PermissionPolicy, PolicyActionTypeInput } from '@/graphql/codegen/graphql';
import { create } from 'zustand';

export interface UserSystemNodesActionsPermissionsState {
  permissionPolicies: PermissionPolicy[];

  parentNodeHighestActionType: PolicyActionTypeInput | null;

  userHasAllAdminPermission: boolean;
  userHasAllDeletePermission: boolean;
  userHasAllMovePermission: boolean;
  userHasAllEditPermission: boolean;
  userHasAllReadPermission: boolean;
}

export const useUserSystemNodesActionsPermissionsStore =
  create<UserSystemNodesActionsPermissionsState>(() => ({
    permissionPolicies: [],
    parentNodeHighestActionType: null,
    userHasAllAdminPermission: false,
    userHasAllDeletePermission: false,
    userHasAllMovePermission: false,
    userHasAllEditPermission: false,
    userHasAllReadPermission: false,
  }));

export const setUserSystemNodesActionsPermissions = (
  userActionsPermissions: Omit<
    UserSystemNodesActionsPermissionsState,
    'parentNodeHighestActionType'
  >,
) => {
  useUserSystemNodesActionsPermissionsStore.setState(() => ({
    ...userActionsPermissions,
  }));
};

export const setParentNodeHighestActionType = ({
  parentNodeHighestActionType,
}: Pick<UserSystemNodesActionsPermissionsState, 'parentNodeHighestActionType'>) => {
  useUserSystemNodesActionsPermissionsStore.setState(() => ({
    parentNodeHighestActionType,
  }));
};
