import { FileNode } from '@/graphql/codegen/graphql';
import { FIND_SYSTEM_FILE_NODE_BY_FILE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (fileId: string) => ['FIND_SYSTEM_FILE_NODE_BY_FILE_ID', fileId];

export const useFindSystemFileNodeByFileIdQuery = (fileId?: string) => {
  const response = useQuery({
    enabled: !!fileId,
    queryFn: () => request(FIND_SYSTEM_FILE_NODE_BY_FILE_ID, { fileId: fileId as string }),
    queryKey: getQueryKey(fileId as string),
    keepPreviousData: true,
  });

  const systemFileNode = useMemo(() => {
    return response.data?.findSystemFileNodeByFileId as FileNode;
  }, [response.data?.findSystemFileNodeByFileId]);

  const systemFileNodeSize = systemFileNode?.file?.storage?.size ?? null;

  return {
    response,
    systemFileNode,
    systemFileNodeSize,
  };
};

useFindSystemFileNodeByFileIdQuery.getQueryKey = getQueryKey;
