import {
  UpdateFileNodeNameMutation,
  UpdateFileNodeNameMutationVariables,
  UpdateFolderNodeNameMutation,
  UpdateFolderNodeNameMutationVariables,
  UpdateSystemNodeNameMutation,
  UpdateSystemNodeNameMutationVariables,
} from '@/graphql/codegen/graphql';
import {
  UPDATE_FILE_NODE_NAME,
  UPDATE_FOLDER_NODE_NAME,
  UPDATE_SYSTEM_NODE_NAME,
} from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useMutation } from '@tanstack/react-query';

export interface UseSystemNodesMutationProps {
  onUpdateFileNodeNameSuccess?: () => void;
  onUpdateFolderNodeNameSuccess?: () => void;
  onUpdateSystemNodeNameSuccess?: () => void;
}

export const useSystemNodesMutation = (props?: UseSystemNodesMutationProps) => {
  const updateFileNodeName = useMutation<
    UpdateFileNodeNameMutation,
    unknown,
    UpdateFileNodeNameMutationVariables,
    unknown
  >({
    mutationFn: payload => request(UPDATE_FILE_NODE_NAME, payload),
    onSuccess: () => {
      props?.onUpdateFileNodeNameSuccess?.();
    },
  });

  const updateFolderNodeName = useMutation<
    UpdateFolderNodeNameMutation,
    unknown,
    UpdateFolderNodeNameMutationVariables,
    unknown
  >({
    mutationFn: payload => request(UPDATE_FOLDER_NODE_NAME, payload),
    onSuccess: () => {
      props?.onUpdateFolderNodeNameSuccess?.();
    },
  });

  const updateSystemNodeName = useMutation<
    UpdateSystemNodeNameMutation,
    unknown,
    UpdateSystemNodeNameMutationVariables,
    unknown
  >({
    mutationFn: payload => request(UPDATE_SYSTEM_NODE_NAME, payload),
    onSuccess: () => {
      props?.onUpdateSystemNodeNameSuccess?.();
    },
  });

  return { updateFileNodeName, updateFolderNodeName, updateSystemNodeName };
};
