import { CREATE_NODE_SHARE_LINK } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useMutation } from '@tanstack/react-query';

export const useCreateSystemNodeShareLink = () => {
  const mutation = useMutation({
    mutationFn: ({
      nodeId,
      name,
      actionType,
    }: {
      nodeId: string;
      name: string;
      actionType: string;
    }) => request(CREATE_NODE_SHARE_LINK, { nodeId, name, actionType }),
  });

  return mutation;
};
