import { FindIcon } from '@/components/IconButton';
import { OBJECT_TYPE, SUBJECT_TYPE } from '@/constants/policy';
import { UserV2, UserGroup } from '@/graphql/codegen/graphql';
import { useListActionTypesByObjectTypeQuery } from '@/hooks/useListActionTypesByObjectTypeQuery';
import {
  SubjectWithPolicy,
  UserOrGroup,
  setEditingSubjectsWithPermissionPolicy,
  setIsOpenAddUserOrGroupDialog,
  setIsOpenEditAccessDialog,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import { displayName } from '@/utils/user';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, CheckBox, Input } from '@skand/ui';
import {
  RowSelectionState,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterButtonMenu, RoleFilter } from './FilterButtonMenu';

const columnHelper = createColumnHelper<UserOrGroup>();

const columns = [
  columnHelper.accessor(
    userOrUserGroup => {
      if (userOrUserGroup.type === SUBJECT_TYPE.USER) return displayName(userOrUserGroup as UserV2);
      return (userOrUserGroup as UserGroup).name;
    },
    {
      id: 'name',
      header: ({ table }) => (
        <label className={cn('flex items-center gap-3', 'hover:cursor-pointer')}>
          <CheckBox
            checked={table.getIsAllRowsSelected()}
            className="hover:cursor-pointer"
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
          name
        </label>
      ),
      cell: ({ row, getValue }) => (
        <label className={cn('flex items-center gap-3', 'hover:cursor-pointer')}>
          <CheckBox
            checked={row.getIsSelected()}
            className="hover:cursor-pointer"
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
          {getValue()}
        </label>
      ),
    },
  ),

  columnHelper.accessor('email', {
    header: 'email',
    cell: ({ getValue }) => getValue() ?? 'N/A',
  }),
];

const initFilter = { users: false, groups: false };

export const AddUserOrUserGroupDialog = () => {
  const editingSystemNodeId = useDataManagementPageStore(state => state.editingSystemNodeId);
  const usersAndGroups = useDataManagementPageStore(state => state.usersAndGroups);
  const editingSubjectsWithPermissionPolicy = useDataManagementPageStore(
    state => state.editingSubjectsWithPermissionPolicy,
  );
  const isOpenAddUserOrGroupDialog = useDataManagementPageStore(
    state => state.isOpenAddUserOrGroupDialog,
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [defaultActionType, setDefaultActionType] = useState<string>('');
  const [filter, setFilter] = useState<RoleFilter>(initFilter);

  const fetchActionTypesByObjectType = useListActionTypesByObjectTypeQuery(OBJECT_TYPE.SYSTEM_NODE);

  const handleSearchWithDebounce = useRef(
    debounce(async keyword => {
      setSearchKeyword(keyword);
    }, 300),
  ).current;

  useEffect(() => {
    const fetchActionTypes = async () => {
      const actionTypes = await fetchActionTypesByObjectType();

      // Get last element (lowest permission) from actionTypes as default
      setDefaultActionType(actionTypes[actionTypes.length - 1] ?? '');
    };

    fetchActionTypes();
  }, [fetchActionTypesByObjectType]);

  useEffect(() => {
    return () => handleSearchWithDebounce.cancel();
  }, [handleSearchWithDebounce]);

  useEffect(() => {
    if (!isOpenAddUserOrGroupDialog) return;

    setFilter(initFilter);
    setSearchKeyword('');
  }, [isOpenAddUserOrGroupDialog]);

  const unselectedUsersAndGroups = useMemo(() => {
    const systemNodePermissionPolicies = editingSubjectsWithPermissionPolicy.map(s => s.policy);

    return usersAndGroups.filter(
      userOrGroup =>
        !systemNodePermissionPolicies.some(
          permissionPolicy => permissionPolicy?.subjectId === userOrGroup.id,
        ),
    );
  }, [editingSubjectsWithPermissionPolicy, usersAndGroups]);

  const searchedOrFilteredUserGroups = useMemo(
    () =>
      unselectedUsersAndGroups.filter(userOrGroup => {
        if (userOrGroup.type === SUBJECT_TYPE.USER && !filter.users && filter.groups) {
          return false;
        }

        if (userOrGroup.type === SUBJECT_TYPE.GROUP && filter.users && !filter.groups) {
          return false;
        }

        if (searchKeyword) {
          const searchLower = searchKeyword.toLowerCase();
          if (userOrGroup.type === SUBJECT_TYPE.USER) {
            const nameLower = displayName(userOrGroup as UserV2).toLowerCase();
            const emailLower = (userOrGroup as UserV2).email?.toLowerCase() || '';
            return nameLower.includes(searchLower) || emailLower?.includes(searchLower);
          } else {
            const nameLower = (userOrGroup as UserGroup).name?.toLowerCase() || '';
            return nameLower.includes(searchLower);
          }
        }

        return true;
      }),
    [filter.groups, filter.users, unselectedUsersAndGroups, searchKeyword],
  );

  const table = useReactTable({
    columns,
    data: searchedOrFilteredUserGroups,
    state: { sorting, rowSelection },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: row => row.id as string,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const handleOpenChange = useCallback((open: boolean) => {
    setRowSelection({});
    setIsOpenAddUserOrGroupDialog(open);

    if (!open) {
      setIsOpenEditAccessDialog(true);
    }
  }, []);

  const handleAddToGroup = useCallback(() => {
    const rowSelectionIds = Object.keys(rowSelection);

    const newSubjectsWithPermissionPolicy = rowSelectionIds.map(selectedUserOrGroupId => {
      const userOrGroupData = usersAndGroups.find(
        userOrGroup => userOrGroup.id === selectedUserOrGroupId,
      );

      const policy = {
        accountId: userOrGroupData?.accountId,
        subjectId: userOrGroupData?.id,
        subjectType: userOrGroupData?.type,
        objectId: editingSystemNodeId,
        objectType: OBJECT_TYPE.SYSTEM_NODE,
        actionType: defaultActionType,
      };

      return {
        ...userOrGroupData,
        policy,
        isNew: true,
      };
    }) as SubjectWithPolicy[];

    setEditingSubjectsWithPermissionPolicy([
      ...editingSubjectsWithPermissionPolicy,
      ...newSubjectsWithPermissionPolicy,
    ]);

    handleOpenChange(false);
  }, [
    rowSelection,
    editingSubjectsWithPermissionPolicy,
    handleOpenChange,
    usersAndGroups,
    editingSystemNodeId,
    defaultActionType,
  ]);

  const handleApplyFilter = useCallback((newFilter: RoleFilter) => {
    setFilter(newFilter);
  }, []);

  return (
    <Dialog.Root onOpenChange={handleOpenChange} open={isOpenAddUserOrGroupDialog}>
      <Dialog.Portal>
        <div
          className={cn(
            'fixed left-0 top-0 z-1 h-full w-full flex items-center justify-center',
            ' bg-black bg-opacity-30',
          )}
        >
          <Dialog.Content
            className={cn(
              'bg-neutral-100',
              'fixed',
              'flex-col',
              'flex',
              'inset-y-12',
              'p-6',
              'rounded-2',
              'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
              'left-50% transform-translate-x--50%',
              'w-640px',
              'mt-auto mb-auto h-70vh',
              'z-10',
            )}
          >
            <Dialog.Title className="color-neutral-800 typo-text-l">Add user/group</Dialog.Title>
            <div
              className={cn(
                'b-1 b-neutral-400 rounded-1 b-solid',
                'p-t-3 p-x-3 p-b-0',
                'mt-6 flex-1 ',
              )}
            >
              <div className="flex gap-3">
                <div className="flex-1">
                  <Input
                    onChange={value => handleSearchWithDebounce(value)}
                    placeholder="Search for users or groups"
                    value={searchKeyword}
                    tail={<FindIcon />}
                  />
                </div>
                <FilterButtonMenu defaultFilter={filter} onApplyFilter={handleApplyFilter} />
              </div>

              <table className="mt-3 w-full border-collapse">
                <thead>
                  {table.getHeaderGroups().map(headerGroup => {
                    return (
                      <tr
                        className={cn(
                          'b-b-1 b-b-neutral-500 b-b-solid text-left',
                          'grid grid-cols-2 p-r-2',
                        )}
                        key={headerGroup.id}
                      >
                        {headerGroup.headers.map(header => {
                          const isSortable = header.column.getCanSort();
                          const sort = header.column.getIsSorted();

                          return (
                            <th
                              className={cn(
                                'py-3 text-left uppercase color-neutral-800 typo-button-xs',
                                {
                                  'hover:cursor-pointer': isSortable,
                                },
                              )}
                              key={`${header.id}-${header.index}`}
                            >
                              <div className="flex items-center">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}

                                {isSortable && (
                                  <div
                                    className={cn(
                                      'i-skand-dropdown ml-2',
                                      'hover:cursor-pointer',
                                      sort === false ? 'color-neutral-400' : 'color-neutral-800',
                                      sort === 'asc' && 'rotate-180',
                                    )}
                                    onClick={header.column.getToggleSortingHandler()}
                                  />
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody
                  className={cn(
                    'grid h-[calc(70vh-268px)] overflow-y-auto auto-rows-max m-y-1',
                    'scrollbar scrollbar-rounded',
                  )}
                  style={{ scrollbarGutter: 'stable' }}
                >
                  {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className="grid grid-cols-2">
                      {row.getVisibleCells().map(cell => (
                        <td className="pt-2 color-neutral-800 typo-text-s" key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className=" flex mt-6 gap-3 justify-end">
              <Dialog.Close asChild>
                <Button
                  className={cn('hover:cursor-pointer', 'flex-1')}
                  onClick={() => handleOpenChange(false)}
                  size="s"
                >
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                className={cn('hover:cursor-pointer', 'flex-1')}
                filled
                onClick={() => handleAddToGroup()}
                primary
                size="s"
              >
                Next
              </Button>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
