import {
  InputMaybe,
  PermissionPolicy,
  PermissionPolicyInput,
  DeletePermissionPoliciesMutation,
  UpsertPermissionPoliciesMutation,
} from '@/graphql/codegen/graphql';
import { DELETE_PERMISSION_POLICIES, UPSERT_PERMISSION_POLICIES } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export interface UsePermissionPolicyMutation {
  upsertPermission: UseMutationResult<
    UpsertPermissionPoliciesMutation,
    unknown,
    PermissionPolicy[],
    unknown
  >;
  deletePermission: UseMutationResult<
    DeletePermissionPoliciesMutation,
    unknown,
    PermissionPolicy[],
    unknown
  >;
}

export interface UsePermissionPolicyMutationProps {
  onUpsertPermissionPoliciesSuccess?: () => void;
  onDeletePermissionPoliciesSuccess?: () => void;
}

export const usePermissionPolicyMutation = (
  props?: UsePermissionPolicyMutationProps,
): UsePermissionPolicyMutation => {
  const upsertPermission = useMutation<
    UpsertPermissionPoliciesMutation,
    unknown,
    PermissionPolicy[],
    unknown
  >({
    mutationFn: permissionPolicies =>
      request(UPSERT_PERMISSION_POLICIES, {
        permissionPolicies: permissionPolicies as InputMaybe<PermissionPolicyInput>[],
      }),
    onSuccess: () => {
      props?.onUpsertPermissionPoliciesSuccess?.();
    },
  });

  const deletePermission = useMutation<
    DeletePermissionPoliciesMutation,
    unknown,
    PermissionPolicy[],
    unknown
  >({
    mutationFn: permissionPolicies =>
      request(DELETE_PERMISSION_POLICIES, {
        permissionPolicies: permissionPolicies as InputMaybe<PermissionPolicyInput>[],
      }),
    onSuccess: () => {
      props?.onDeletePermissionPoliciesSuccess?.();
    },
  });

  return { upsertPermission, deletePermission };
};
