import { OBJECT_ID } from '@/constants/policy';
import { PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isDefined } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (args: { subjectId: string; objectIds: string[] | null }) => [
  'LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_GROUPING_POLICIES_PERMISSIONS',
  { ...args },
];

export const useListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds = ({
  objectIds,
  userId,
}: {
  objectIds: string[] | null;
  userId: string | null;
}) => {
  const response = useQuery({
    enabled: !!userId,
    queryFn: () =>
      request(LIST_SUBJECT_TO_OBJECTS_PERMISSION_POLICIES_WITH_EXPANDED_SUBJECT_IDS, {
        subjectId: userId as string,
        objectIds: objectIds ? [OBJECT_ID.ALL, ...objectIds] : [OBJECT_ID.ALL],
        objectTypes: [PolicyObjectTypeInput.All, PolicyObjectTypeInput.SystemNode],
        actionTypes: null,
      }),
    queryKey: getQueryKey({
      subjectId: userId as string,
      objectIds,
    }),
    refetchOnWindowFocus: true,
  });

  const permissionPolicies = useMemo(() => {
    return (
      response.data?.listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds?.filter(
        isDefined,
      ) || []
    );
  }, [response.data?.listSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds]);

  return {
    permissionPolicies,
    response,
  };
};

useListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds.getQueryKey = getQueryKey;
