import { UserV2 } from '@/graphql/codegen/graphql';
import { LIST_USERS_BY_ACCOUNT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['USERS_BY_ACCOUNT_ID'];

export const useUsersQuery = () => {
  const response = useQuery({
    queryFn: () => request(LIST_USERS_BY_ACCOUNT_ID),
    queryKey,
  });

  const users: Solid<UserV2, 'id'>[] = useMemo(() => {
    return response.data?.listUsersByAccountId?.filter(hasId) ?? [];
  }, [response.data?.listUsersByAccountId]);

  return { response, users };
};

useUsersQuery.queryKey = queryKey;
