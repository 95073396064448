import { cn } from '@/utils/classname';
import {
  autoUpdate,
  flip,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Input } from '@skand/ui';
import { HTMLAttributes, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectItem } from './SelectItem';
import { FindIcon } from '@/components/IconButton';

export interface SelectItem<K extends string> {
  disabled?: boolean;
  key: K;
  name: string;
  tail?: ReactNode;
}

export interface SelectProps<K extends string>
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  disabled?: boolean;
  hint?: string;
  label?: string;
  onChange?: (key: K) => void;
  options: SelectItem<K>[];
  placeholder?: string;
  tail?: null | ReactNode;
  value?: null | K;
  width?: 'auto' | 'full';
  xPlacement?: 'left' | 'right';
  yPlacement?: 'top' | 'bottom';
  warn?: boolean;
}

export const SelectWithSearch = <K extends string>({
  disabled,
  hint,
  label,
  onChange,
  options,
  placeholder,
  tail,
  value,
  width = 'auto',
  xPlacement = 'left',
  yPlacement = 'bottom',
  warn,
  ...props
}: SelectProps<K>) => {
  const [key, setKey] = useState<null | K>(value ?? null);

  const option = useMemo(() => options.find(option => option.key === key) ?? null, [key, options]);

  const [isOpen, setIsOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState(''); // Step 1

  const placement = useMemo(() => {
    const x = xPlacement === 'left' ? 'start' : 'end';
    return `${yPlacement}-${x}` as const;
  }, [xPlacement, yPlacement]);

  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${Math.max(140, availableHeight)}px`,
          });
        },
      }),
      flip({
        fallbackStrategy: 'initialPlacement',
      }),
    ],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement,
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context, { enabled: !disabled });
  const dismiss = useDismiss(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);

  const handleClick = useCallback(
    (key: K) => {
      const option = options.find(op => op.key === key);
      if (option?.disabled) return;

      setKey(option?.key || null);
      setIsOpen(false);
      onChange?.(key);
    },
    [onChange, options],
  );

  useEffect(() => {
    setKey(value ?? null);
  }, [value]);

  const localTail = useMemo(() => {
    if (option && option.tail) {
      return option.tail;
    }

    if (tail === undefined)
      return <div className={cn('color-neutral-400', 'i-skand-dropdown', 'm-l-4')} />;

    if (tail) return tail;

    return null;
  }, [option, tail]);

  const hasSelected = !!option;

  const filteredOptions = useMemo(() => {
    if (searchTerm === '') return options;
    return options.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [options, searchTerm]);

  return (
    <div className={cn('group/select', 'relative', 'inline-block', 'w-320px')} {...props}>
      <label
        className={cn(
          'border-1',
          'border-solid',
          'flex-nowrap',
          'flex-row',
          'flex',
          'items-center',
          'justify-between',
          'p-x-3',
          'p-y-2',
          'rounded-1',
          'typo-text-s',
          'color-neutral-600',
          'cursor-pointer',

          disabled && warn && 'border-alert-300 bg-neutral-300',
          disabled && !warn && 'bg-neutral-300 border-neutral-300',
          warn && !disabled && 'border-alert-300 bg-neutral-100',
          !disabled && !warn && 'bg-neutral-100 border-neutral-400',
        )}
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps()}
      >
        {hasSelected ? (
          <div>
            {label && <div className={cn('typo-text-xxs', 'color-neutral-800')}>{label}</div>}
            <div className={cn('typo-text-s', 'color-neutral-600')}>{option?.name}</div>
          </div>
        ) : (
          <div>
            {label ? (
              <span className={cn('typo-text-s', 'color-neutral-600')}>{label}</span>
            ) : (
              placeholder && (
                <span className={cn('typo-text-s', 'color-neutral-400')}>{placeholder}</span>
              )
            )}
          </div>
        )}
        {localTail}
      </label>

      {hint && (
        <span
          className={cn('m-t-1', 'typo-text-xs', warn ? 'color-alert-300' : 'color-neutral-600')}
        >
          {hint}
        </span>
      )}

      {!disabled && isOpen && (
        <ul
          className={cn(
            'absolute',
            'bg-neutral-100',
            'border-1',
            'border-neutral-400',
            'border-solid',
            'list-none',
            'overflow-auto',
            'p-1',
            'rounded-1',
            'z-1',
            'h-fit',

            (width === 'full' || options.length === 0) && 'w-full',
          )}
          ref={refs.setFloating}
          style={floatingStyles}
          tabIndex={0}
          {...getFloatingProps()}
        >
          {options.length === 0 && (
            <li
              className={cn(
                'color-neutral-400',
                'flex-nowrap',
                'flex',
                'items-center',
                'justify-between',
                'px-2',
                'py-2px',
                'rounded-2px',
                'typo-text-s',
                '',
              )}
              onClick={() => setIsOpen(false)}
            >
              No options
            </li>
          )}

          <div className=" top-0 z-10 bg-white my-1">
            <Input placeholder="Search" onChange={e => setSearchTerm(e)} tail={<FindIcon />} />
          </div>

          <div className={cn('', 'overflow-auto', 'max-h-300px')}>
            {filteredOptions.map(option => {
              return (
                <SelectItem
                  className={cn(option.disabled && 'disabled')}
                  key={option.key}
                  onClick={() => handleClick(option.key)}
                >
                  <div className="whitespace-nowrap">{option.name}</div>
                  <div>{option.tail}</div>
                </SelectItem>
              );
            })}
          </div>
        </ul>
      )}
    </div>
  );
};
