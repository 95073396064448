import { DownloadSystemNodesMutationVariables, File } from '@/graphql/codegen/graphql';
import { DOWNLOAD_SYSTEM_NODES } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useGetSignedObjectDownloadUrl } from '@/hooks/useGetSignedObjectDownloadUrl';
import { downloadFiles, setIsOpenDownloadDialog, setIsRequestingDownload } from '@/stores/download';
import { DATASETS_ADVANCED_DOWNLOADER } from '@/utils/split';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuItem } from '@skand/ui';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { FoldersPageTableData } from './useFoldersPageColumns';

export interface DownloadMenuItemProps {
  fileId?: string;
  nodeId: string;
  selectedRows: FoldersPageTableData[];
  disabled?: boolean;
}

export const DownloadMenuItem = ({ fileId, nodeId, disabled = false }: DownloadMenuItemProps) => {
  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER]);
  const isAdvancedDownloaderEnabled = treatments[DATASETS_ADVANCED_DOWNLOADER].treatment === 'on';

  const locallySignedUrl = useGetSignedObjectDownloadUrl(fileId ? [fileId] : []);

  const downloadSystemNodes = useMutation({
    mutationFn: (variables: DownloadSystemNodesMutationVariables) => {
      return request(DOWNLOAD_SYSTEM_NODES, variables);
    },
  });

  const handleDownloadFile = useCallback(async () => {
    if (fileId && locallySignedUrl) {
      downloadFiles(locallySignedUrl as File[], isAdvancedDownloaderEnabled);
    } else {
      downloadSystemNodes.mutate({ nodeIds: [nodeId] });
      setIsRequestingDownload(true);
    }

    setIsOpenDownloadDialog(true);
  }, [fileId, locallySignedUrl, isAdvancedDownloaderEnabled, downloadSystemNodes, nodeId]);

  return (
    <DropdownMenu.Item asChild className="outline-none cursor-pointer">
      <MenuItem onClick={() => handleDownloadFile()} disabled={disabled}>
        Download
      </MenuItem>
    </DropdownMenu.Item>
  );
};
