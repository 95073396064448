import { ACCESS_TOKEN_NAME } from '@/constants/env';
import Cookies from 'js-cookie';

export const checkAuth = () => {
  const hasAccessToken = !!Cookies.get(ACCESS_TOKEN_NAME);
  return hasAccessToken;
};

export const getAuth = () => {
  return Cookies.get(ACCESS_TOKEN_NAME);
};

export const clearAuth = () => {
  Cookies.remove(ACCESS_TOKEN_NAME);
};
