export const ANALYTICS_EVENT_OBJECT = {
  CREATE_FOLDER: 'create-folder',
  NEW_FOLDER_NAME: 'new-folder-name',
  SAVE_FOLDER_NAME: 'save-folder-name',
  FOLDER_NAME: 'folder-name',
  INITIATE_UPLOAD: 'initiate-upload',
  DRAG_AND_DROP_FILES: 'drag-and-drop-files',
  START_UPLOADING: 'start-uploading',
  CLOSE_UPLOAD_MODAL: 'close-upload-modal',
  SEARCH_CURRENT_FOLDER: 'search-current-folder',
  SEARCH_RECURSIVELY: 'search-recursively',
};
export type AnalyticsEventObject = keyof typeof ANALYTICS_EVENT_OBJECT;
