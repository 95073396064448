import { cn } from '@/utils/classname';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Slot } from '@radix-ui/react-slot';
import { Button, CheckBox } from '@skand/ui';
import { useCallback, useEffect, useState } from 'react';

export interface RoleFilter {
  users: boolean;
  groups: boolean;
}

export interface FilterButtonMenuProps {
  defaultFilter: RoleFilter;
  onApplyFilter: (filter: RoleFilter) => void;
}

export const FilterButtonMenu = ({ defaultFilter, onApplyFilter }: FilterButtonMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<RoleFilter>(defaultFilter);

  useEffect(() => {
    if (open) {
      setFilter(defaultFilter);
    }
  }, [open, defaultFilter]);

  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: 'bottom-end',
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);

  const handleToggleFilter = (key: keyof RoleFilter) => {
    setFilter(prev => ({ ...prev, [key]: !prev[key] as boolean }));
  };

  const handleApplyFilter = useCallback(() => {
    onApplyFilter(filter);
    setOpen(false);
  }, [filter, onApplyFilter]);

  return (
    <>
      <Button
        className={cn('hover:cursor-pointer', 'w-64px')}
        ref={refs.setReference}
        {...getReferenceProps()}
        size="s"
      >
        Filter
      </Button>
      {open && (
        <Slot ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
          <div
            className={cn(
              'b-1 b-neutral-600 b-solid rounded',
              'w-300px p-3',
              'bg-neutral-100',
              'flex flex-col gap-3',
              'z-1',
            )}
          >
            <div className="flex items-center justify-between">
              <h3 className="color-neutral-800 typo-text-m">Filter options</h3>
              <div
                className={cn('i-skand-close text-3 color-neutral-400', 'hover:cursor-pointer')}
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="color-neutral-800 typo-text-s flex flex-col gap-2">
              <label className={cn('flex items-center gap-3', 'hover:cursor-pointer')}>
                <CheckBox
                  checked={filter.users}
                  className="hover:cursor-pointer"
                  onChange={() => handleToggleFilter('users')}
                />
                Users
              </label>
              <label className={cn('flex items-center gap-3', 'hover:cursor-pointer')}>
                <CheckBox
                  checked={filter.groups}
                  className="hover:cursor-pointer"
                  onChange={() => handleToggleFilter('groups')}
                />
                Groups
              </label>
            </div>
            <div className="flex gap-3">
              <Button
                className={cn('hover:cursor-pointer', 'flex-1')}
                onClick={() => setFilter({ users: false, groups: false })}
                size="s"
              >
                Clear filter
              </Button>
              <Button
                className={cn('hover:cursor-pointer', 'flex-1')}
                filled
                onClick={() => handleApplyFilter()}
                primary
                size="s"
              >
                Apply
              </Button>
            </div>
          </div>
        </Slot>
      )}
    </>
  );
};
