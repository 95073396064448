import { cn } from '@/utils/classname';
import { flexRender, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { HTMLAttributes, useRef } from 'react';

export interface TableDenseProps<T> extends HTMLAttributes<HTMLTableElement> {
  table: ReturnType<typeof useReactTable<T>>;
}

export const TableDense = <T,>({ table, className }: TableDenseProps<T>) => {
  const parentRef = useRef<HTMLTableElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36,
    overscan: 8,
  });

  return (
    <div ref={parentRef} className="overflow-auto h-240px w-full">
      <table className={cn('w-full grid', className)}>
        <thead className="grid sticky top-0 z-1 bg-neutral-100">
          {table.getHeaderGroups().map(headerGroup => {
            return (
              <tr
                className={cn(
                  'b-t-1 b-t-neutral-500 b-t-solid b-b-1 b-b-neutral-500 b-b-solid text-left flex gap-2',
                )}
                key={headerGroup.id}
              >
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      className={cn('py-2 uppercase typo-button-xs color-neutral-800')}
                      style={{ width: header.getSize() }}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center">
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody className="grid relative" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {rowVirtualizer.getVirtualItems().map(virtualRow => {
            const row = rows[virtualRow.index];

            return (
              <tr
                key={row.id}
                className="flex absolute w-full gap-2"
                style={{
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    className={cn('py-2 typo-text-s color-neutral-800')}
                    style={{ width: cell.column.getSize() }}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
