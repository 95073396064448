import { TableDataPlaceholder } from '@/components/TableDataPlaceholder';
import { ActionType, OBJECT_TYPE, ObjectType, SUBJECT_TYPE, SubjectType } from '@/constants/policy';
import { queryClient } from '@/graphql/client';
import { PermissionPolicy, UserGroup, UserV2 } from '@/graphql/codegen/graphql';
import { useListActionTypesByObjectTypeQuery } from '@/hooks/useListActionTypesByObjectTypeQuery';
import { useListPermissionPoliciesWithAccountContextQuery } from '@/hooks/useListPermissionPoliciesWithAccountContextQuery';
import { usePermissionPolicyMutation } from '@/hooks/usePermissionPolicyMutation';
import { useUserGroupsQuery } from '@/hooks/useUserGroupsQuery';
import { useUsersQuery } from '@/hooks/useUsersQuery';
import {
  removeEditingSubjectsWithPermissionPolicyById,
  setEditingSubjectsWithPermissionPolicy,
  setEditingSystemNodeId,
  setIsOpenAddUserOrGroupDialog,
  setIsOpenEditAccessDialog,
  setUsersAndGroups,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import { cn } from '@/utils/classname';
import { displayName } from '@/utils/user';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@skand/ui';
import { useCallback, useEffect, useState } from 'react';
import PermissionsSelect from './PermissionsSelect';

type UserWithType = UserV2 & { type: SubjectType };

type UserGroupWithType = UserGroup & { type: SubjectType };

type UserOrGroup = UserWithType | UserGroupWithType;

type SubjectWithPolicy = UserOrGroup & {
  policy: PermissionPolicy;
  isUpdated?: boolean;
  isDeleted?: boolean;
};

export const EditAccessDialog = () => {
  const editingSystemNodeId = useDataManagementPageStore(state => state.editingSystemNodeId);
  const editingSubjectsWithPermissionPolicy = useDataManagementPageStore(
    state => state.editingSubjectsWithPermissionPolicy,
  );
  const isOpenEditAccessDialog = useDataManagementPageStore(state => state.isOpenEditAccessDialog);
  const usersAndGroups = useDataManagementPageStore(state => state.usersAndGroups);

  const { users } = useUsersQuery();
  const { groups } = useUserGroupsQuery();

  const fetchActionTypesByObjectType = useListActionTypesByObjectTypeQuery(OBJECT_TYPE.SYSTEM_NODE);

  const [actionTypes, setActionTypes] = useState<string[]>([]);

  useEffect(() => {
    const fetchActionTypes = async () => {
      const actionTypes = await fetchActionTypesByObjectType();
      setActionTypes(actionTypes.filter((actionType): actionType is string => actionType !== null));
    };

    fetchActionTypes();
  }, [fetchActionTypesByObjectType]);

  const {
    permissionPolicies,
    response: { isFetching: isFetchingPermissionPolicies },
  } = useListPermissionPoliciesWithAccountContextQuery({
    objectId: editingSystemNodeId,
    objectType: OBJECT_TYPE.SYSTEM_NODE as ObjectType,
    subjectId: null,
    subjectType: null,
    actionType: null,
  });

  const handleUpsertOrDeletePermissionSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      useListPermissionPoliciesWithAccountContextQuery.getQueryKey({
        objectId: editingSystemNodeId,
        objectType: OBJECT_TYPE.SYSTEM_NODE as ObjectType,
        subjectId: null,
        subjectType: null,
        actionType: null,
      }),
    );
  }, [editingSystemNodeId]);

  const { upsertPermission, deletePermission } = usePermissionPolicyMutation({
    onUpsertPermissionPoliciesSuccess: handleUpsertOrDeletePermissionSuccess,
    onDeletePermissionPoliciesSuccess: handleUpsertOrDeletePermissionSuccess,
  });

  useEffect(() => {
    setUsersAndGroups([
      ...(users.map(user => ({ ...user, type: SUBJECT_TYPE.USER })) as UserOrGroup[]),
      ...(groups.map(user => ({ ...user, type: SUBJECT_TYPE.GROUP })) as UserOrGroup[]),
    ]);
  }, [users, groups]);

  useEffect(() => {
    const isSubjectsWithPermissionInitialized = editingSubjectsWithPermissionPolicy.length > 0;
    if (
      !usersAndGroups ||
      !permissionPolicies ||
      !isOpenEditAccessDialog ||
      isSubjectsWithPermissionInitialized
    )
      return;

    const subjectsWithPolicy = permissionPolicies.map(policy => ({
      ...usersAndGroups.find(userOrGroup => userOrGroup.id === policy?.subjectId),
      policy,
    })) as SubjectWithPolicy[];

    setEditingSubjectsWithPermissionPolicy([...subjectsWithPolicy]);

    // Don't depend on editingSubjectsWithPermissionPolicy changes to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAndGroups, permissionPolicies, isOpenEditAccessDialog]);

  const handleChangePermission = useCallback((subjectId: string, newActionType: ActionType) => {
    useDataManagementPageStore.setState(state => {
      const editingSubjectsWithPermissionPolicy = [...state.editingSubjectsWithPermissionPolicy];

      const updateIndex = editingSubjectsWithPermissionPolicy.findIndex(
        subject => subject.id === subjectId,
      );
      if (updateIndex !== -1) {
        const tempRecord = { ...editingSubjectsWithPermissionPolicy[updateIndex] };

        editingSubjectsWithPermissionPolicy[updateIndex] = {
          ...tempRecord,
          policy: {
            ...tempRecord.policy,
            actionType: newActionType,
          },
          isUpdated: true,
        };
      }

      return {
        editingSubjectsWithPermissionPolicy,
      };
    });
  }, []);

  const handleDelete = useCallback((subjectId: string) => {
    removeEditingSubjectsWithPermissionPolicyById(subjectId);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsOpenEditAccessDialog(false);
    setEditingSystemNodeId(null);
    setEditingSubjectsWithPermissionPolicy([]);
  }, []);

  const handleSaveChanges = useCallback(async () => {
    const { toUpdatePermissionPolicies, toAddPermissionPolicies } =
      editingSubjectsWithPermissionPolicy.reduce(
        (acc, currSubjectWithPolicy) => {
          const policy = currSubjectWithPolicy.policy;
          delete policy.createdAt;
          delete policy.updatedAt;

          if (currSubjectWithPolicy.isUpdated) {
            return {
              ...acc,
              toUpdatePermissionPolicies: [...acc.toUpdatePermissionPolicies, policy],
            };
          }

          if (currSubjectWithPolicy.isNew) {
            return {
              ...acc,
              toAddPermissionPolicies: [...acc.toAddPermissionPolicies, policy],
            };
          }

          return acc;
        },
        {
          toUpdatePermissionPolicies: [] as PermissionPolicy[],
          toAddPermissionPolicies: [] as PermissionPolicy[],
        },
      );

    const toDeletePermissionPolicies = permissionPolicies.reduce((acc, currPolicy) => {
      const exist = editingSubjectsWithPermissionPolicy.some(
        subject => subject.id === currPolicy?.subjectId,
      );

      if (exist) return acc;

      const policy = { ...currPolicy };
      delete policy.createdAt;
      delete policy.updatedAt;

      return [...acc, policy];
    }, [] as PermissionPolicy[]);

    const toUpsertPermissionPolicies = [...toUpdatePermissionPolicies, ...toAddPermissionPolicies];
    if (toUpsertPermissionPolicies.length > 0) {
      await upsertPermission.mutateAsync(toUpsertPermissionPolicies);
    }

    if (toDeletePermissionPolicies.length > 0) {
      // Reset any changes in actionType back to its original value before performing delete
      const toDeletePermissions = toDeletePermissionPolicies.map(policy => ({
        ...policy,
        actionType: permissionPolicies?.find(
          p => p?.objectId === policy.objectId && p?.subjectId === policy.subjectId,
        )?.actionType,
      }));

      await deletePermission.mutateAsync(toDeletePermissions);
    }

    handleCloseDialog();
  }, [
    editingSubjectsWithPermissionPolicy,
    handleCloseDialog,
    upsertPermission,
    deletePermission,
    permissionPolicies,
  ]);

  const handleOpenAddUserOrUserGroupDialog = useCallback(() => {
    setIsOpenEditAccessDialog(false);
    setIsOpenAddUserOrGroupDialog(true);
  }, []);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpenEditAccessDialog(open);

      if (!open) {
        handleCloseDialog();
      }
    },
    [handleCloseDialog],
  );

  return (
    <Dialog.Root onOpenChange={handleOpenChange} open={isOpenEditAccessDialog}>
      <Dialog.Portal>
        <div
          className={cn(
            'fixed left-0 top-0 z-1  h-full w-full flex items-center justify-center',
            ' bg-black bg-opacity-30',
          )}
        >
          <Dialog.Content
            className={cn(
              'bg-neutral-100',
              'fixed',
              'flex-col',
              'flex',
              'inset-y-12',
              'p-6',
              'rounded-2',
              'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
              'left-50% transform-translate-x--50%',
              'w-640px',
              'mt-auto mb-auto h-70vh',
              'z-10',
            )}
          >
            <Dialog.Title>
              <div className="flex justify-between items-center">
                <h1 className="color-neutral-800 typo-text-l">Edit access</h1>
                <Button
                  className={cn('hover:cursor-pointer', 'w-134px')}
                  primary
                  onClick={() => handleOpenAddUserOrUserGroupDialog()}
                  size="s"
                  disabled={isFetchingPermissionPolicies}
                >
                  Add user/group
                </Button>
              </div>
            </Dialog.Title>

            <div className="mt-2 color-neutral-800 typo-text-s">
              Add or edit user and group permissions for this folder.
            </div>

            <div className="flex-1">
              <table className="mt-3 w-full border-collapse table-auto">
                <thead>
                  <tr
                    className={cn(
                      'b-t-1 b-t-neutral-500 b-t-solid',
                      'b-b-1 b-b-neutral-500 b-b-solid',
                      'text-left',
                      'grid grid-cols-3',
                      'p-r-6',
                    )}
                  >
                    <th className="py-3 text-left uppercase color-neutral-800 typo-button-xs w-2/5">
                      Users/Groups
                    </th>
                    <th className="py-3 text-left uppercase color-neutral-800 typo-button-xs">
                      Permissions
                    </th>
                  </tr>
                </thead>

                <tbody
                  className={cn(
                    'grid h-[calc(70vh-220px)] overflow-y-auto auto-rows-max m-y-1 p-y-1',
                    'scrollbar scrollbar-rounded',
                  )}
                  style={{ scrollbarGutter: 'stable' }}
                >
                  {editingSubjectsWithPermissionPolicy.length > 0 ? (
                    editingSubjectsWithPermissionPolicy.map(subjectWithPolicy => (
                      <tr
                        key={subjectWithPolicy.id}
                        className={cn('grid grid-cols-3', 'items-center p-r-5')}
                      >
                        <td className="color-neutral-800 typo-text-s pt-2">
                          {subjectWithPolicy.type === SUBJECT_TYPE.USER
                            ? displayName(subjectWithPolicy as UserV2)
                            : (subjectWithPolicy as UserGroup).name}
                        </td>
                        <td className="pt-2">
                          <PermissionsSelect
                            subjectId={subjectWithPolicy.id as string}
                            actionType={subjectWithPolicy.policy.actionType as ActionType}
                            validActionTypes={actionTypes}
                            onChangePermission={handleChangePermission}
                          />
                        </td>
                        <td className="pt-2">
                          <div className="flex justify-end">
                            <div
                              onClick={() => handleDelete(subjectWithPolicy.id as string)}
                              className={cn(
                                'i-skand-close text-3 color-neutral-400',
                                'hover:cursor-pointer',
                              )}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <TableDataPlaceholder isFetching={isFetchingPermissionPolicies} />
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex gap-3 justify-between">
              <Dialog.Close asChild>
                <Button
                  className={cn('hover:cursor-pointer ', 'flex-1')}
                  onClick={() => handleCloseDialog()}
                  size="s"
                >
                  Cancel
                </Button>
              </Dialog.Close>
              <Button
                className={cn('hover:cursor-pointer', 'flex-1')}
                filled
                onClick={() => handleSaveChanges()}
                primary
                size="s"
              >
                Save
              </Button>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
