export const DownloadState = {
  COMPLETED: 'completed',
  DOWNLOADING: 'downloading',
  FAILED: 'failed',
  STOPPED: 'stopped',
} as const;
export type TDownloadState = (typeof DownloadState)[keyof typeof DownloadState];

export type DownloaderError = unknown;

export type EventArgsMap = {
  [key: `complete:${string}`]: [blob: Blob];
  [key: `error:${string}`]: [error: DownloaderError];
  [key: `progress:${string}`]: [progress: number];
  add: [url: string];
  cancel: [url: string];
  complete: [url: string, blob: Blob];
  error: [url: string, error: DownloaderError];
  progress: [url: string, progress: number];
  start: [url: string];
  stop: [url: string];
};
export type EventCallbackMap = {
  [E in keyof EventArgsMap]: (...args: EventArgsMap[E]) => void;
};
export type EventName = keyof EventCallbackMap;
export type EventCallback = EventCallbackMap[keyof EventCallbackMap];
