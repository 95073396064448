import { ActionType } from '@/constants/policy';
import { cn } from '@/utils/classname';
import * as Select from '@radix-ui/react-select';
import { convertToTitleCase } from '@/utils/misc';
export interface PermissionsSelectProps {
  subjectId: string;
  actionType: ActionType;
  validActionTypes: string[];
  onChangePermission: (subjectId: string, newPermission: ActionType) => void;
}

const PermissionsSelect = ({
  subjectId,
  actionType,
  validActionTypes,
  onChangePermission,
}: PermissionsSelectProps) => {
  const handleClick = (permission: ActionType) => {
    onChangePermission(subjectId, permission);
  };

  return (
    <Select.Root onValueChange={handleClick} value={actionType}>
      <Select.Trigger asChild>
        <div
          className={cn(
            'px-12px py-8px w-45',
            'typo-text-s b-1 b-solid b-rd-1 b-neutral-400',
            'flex justify-between items-center',
            'color-neutral-600 hover:cursor-pointer',
          )}
        >
          <Select.Value placeholder="Select a permission" />
          <Select.Icon className="i-skand-dropdown color-neutral-400" />
        </div>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={cn(
            'z-1 bg-neutral-100 p-1 w-45',
            'typo-text-s b-1 b-solid b-rd-1 b-neutral-400',
          )}
          side="bottom"
          position="popper"
          sideOffset={5}
        >
          <Select.Viewport>
            {validActionTypes.map(permission => (
              <Select.Item
                key={permission}
                className={cn('hover:cursor-pointer hover:bg-neutral-200', 'color-neutral-800 p-2')}
                value={permission}
              >
                <Select.ItemText>
                  {convertToTitleCase(permission.replace(/_/g, ' '))}
                </Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default PermissionsSelect;
