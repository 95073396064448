import { OBJECT_ID, OBJECT_TYPE } from '@/constants/policy';
import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { useMemo } from 'react';
import { useListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds } from './useListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds';
import { useReadUserByContext } from './useReadUserByContext';
import { getShareLinkToken } from '@/utils/shareLink';
import { useIsSuperAdmin } from './useIsSuperAdmin';

export const useContextUserSystemNodesActionPermissions = ({
  objectIds,
}: {
  objectIds: string[] | null;
}) => {
  const { user } = useReadUserByContext();
  const isSuperAdmin = useIsSuperAdmin();
  const { permissionPolicies } = useListSubjectToObjectsPermissionPoliciesWithExpandedSubjectIds({
    objectIds,
    userId: user?.id ?? getShareLinkToken() ?? null,
  });

  const {
    userHasAllAdminPermission,
    userHasAllDeletePermission,
    userHasAllMovePermission,
    userHasAllEditPermission,
    userHasAllReadPermission,
  } = useMemo(() => {
    const hasAllAdminPermission =
      isSuperAdmin ||
      permissionPolicies.some(
        permissionPolicy =>
          permissionPolicy?.actionType === PolicyActionTypeInput.Admin &&
          permissionPolicy?.objectId === OBJECT_ID.ALL &&
          (permissionPolicy?.objectType === OBJECT_TYPE.ALL ||
            permissionPolicy?.objectType === PolicyObjectTypeInput.SystemNode),
      );

    const hasAllDeletePermission =
      hasAllAdminPermission ||
      permissionPolicies.some(
        permissionPolicy =>
          permissionPolicy?.actionType === PolicyActionTypeInput.Delete &&
          permissionPolicy?.objectId === OBJECT_ID.ALL &&
          permissionPolicy?.objectType === PolicyObjectTypeInput.SystemNode,
      );

    const hasAllMovePermission =
      hasAllDeletePermission ||
      permissionPolicies.some(
        permissionPolicy =>
          permissionPolicy?.actionType === PolicyActionTypeInput.Move &&
          permissionPolicy?.objectId === OBJECT_ID.ALL &&
          permissionPolicy?.objectType === PolicyObjectTypeInput.SystemNode,
      );

    const hasAllEditPermission =
      hasAllMovePermission ||
      permissionPolicies.some(
        permissionPolicy =>
          permissionPolicy?.actionType === PolicyActionTypeInput.Edit &&
          permissionPolicy?.objectId === OBJECT_ID.ALL &&
          permissionPolicy?.objectType === PolicyObjectTypeInput.SystemNode,
      );

    const hasAllReadPermission =
      hasAllEditPermission ||
      permissionPolicies.some(
        permissionPolicy =>
          permissionPolicy?.actionType === PolicyActionTypeInput.Read &&
          permissionPolicy?.objectId === OBJECT_ID.ALL &&
          permissionPolicy?.objectType === PolicyObjectTypeInput.SystemNode,
      );

    return {
      userHasAllAdminPermission: hasAllAdminPermission,
      userHasAllDeletePermission: hasAllDeletePermission,
      userHasAllMovePermission: hasAllMovePermission,
      userHasAllEditPermission: hasAllEditPermission,
      userHasAllReadPermission: hasAllReadPermission,
    };
  }, [permissionPolicies, isSuperAdmin]);

  return {
    permissionPolicies,
    userHasAllAdminPermission,
    userHasAllDeletePermission,
    userHasAllMovePermission,
    userHasAllEditPermission,
    userHasAllReadPermission,
  };
};
