import { NODE_KIND } from '@/constants/node';
import { SystemNode } from '@/stores/dataManagementStore';

export const extractSystemNodeNameAndExtension = (
  systemNode: SystemNode,
): { name: string; extension: string } => {
  if (!systemNode) return { name: '', extension: '' };

  const splitName = systemNode.name?.split('.');

  let extension = '';

  if (systemNode.kind === NODE_KIND.FOLDER_NODE) {
    extension = 'FOLDER';
  } else {
    extension = !((splitName?.length || 0) > 1) ? '' : (splitName?.pop()?.toUpperCase() as string);
  }

  const name =
    systemNode?.kind === NODE_KIND.FOLDER_NODE
      ? (systemNode?.name as string)
      : (splitName?.join('.') as string);

  return { name, extension };
};

export const extractDisplaySystemNodeName = (kind: string, name: string): string => {
  if (kind === NODE_KIND.FOLDER_NODE) {
    return name;
  } else {
    const splitName = name.split('.');
    if (splitName.length > 1) {
      splitName.pop();
    }
    return splitName.join('.');
  }
};
