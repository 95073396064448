import { PolicyActionTypeInput } from '@/graphql/codegen/graphql';
import { useUserSystemNodesActionsPermissionsStore } from '@/stores/userSystemNodesActionsPermissions';
import { useMemo } from 'react';

export const useSystemNodesUserActionsPermissionsBySystemNodeIds = ({
  systemNodeIds,
}: {
  systemNodeIds: string[];
}) => {
  const {
    permissionPolicies,
    parentNodeHighestActionType,
    userHasAllAdminPermission,
    userHasAllDeletePermission,
    userHasAllMovePermission,
    userHasAllEditPermission,
    userHasAllReadPermission,
  } = useUserSystemNodesActionsPermissionsStore();

  const userActionsPermission = useMemo(() => {
    const checkActionTypeExistsInPermissionsList = (actionType: PolicyActionTypeInput) => {
      if (systemNodeIds.length === 0) return false;

      return systemNodeIds.every(systemNodeId =>
        permissionPolicies.some(
          permission =>
            permission.objectId === systemNodeId && permission.actionType === actionType,
        ),
      );
    };

    const userHasAdminPermission =
      userHasAllAdminPermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Admin ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Admin);

    const userHasDeletePermission =
      userHasAdminPermission ||
      userHasAllDeletePermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Delete ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Delete);

    const userHasMovePermission =
      userHasDeletePermission ||
      userHasAllMovePermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Move ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Move);

    const userHasEditPermission =
      userHasMovePermission ||
      userHasAllEditPermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Edit ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Edit);

    const userHasReadPermission =
      userHasEditPermission ||
      userHasAllReadPermission ||
      parentNodeHighestActionType === PolicyActionTypeInput.Read ||
      checkActionTypeExistsInPermissionsList(PolicyActionTypeInput.Read);

    return {
      userHasAdminPermission,
      userHasDeletePermission,
      userHasMovePermission,
      userHasEditPermission,
      userHasReadPermission,
    };
  }, [
    parentNodeHighestActionType,
    permissionPolicies,
    systemNodeIds,
    userHasAllAdminPermission,
    userHasAllDeletePermission,
    userHasAllEditPermission,
    userHasAllMovePermission,
    userHasAllReadPermission,
  ]);

  return userActionsPermission;
};
