import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { queryClient } from '@/graphql/client';
import { request } from '@/graphql/request';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';
import {
  startFolderNodeCreation,
  useRecentlyCreatedFolderNodeStore,
} from '@/stores/recentlyCreatedFolderNodesStore';
import { ADVANCED_UPLOADER } from '@/utils/split';
import { Button } from '@skand/ui';
import { QueryClientProvider, RequestContextProvider } from '@skand/uploader';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Upload } from './Upload';
import { UploadLegacy } from './UploadLegacy';

export const HeaderActions = () => {
  const { id } = useParams<{ id: string }>();

  const { isFolderNodeCreationStateActive } = useRecentlyCreatedFolderNodeStore(state => state);

  const systemNodeIds = useMemo(() => (id ? [id] : []), [id]);
  const { userHasEditPermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds,
  });

  const treatments = useTreatments([ADVANCED_UPLOADER]);
  const isAdvancedUploaderEnabled = treatments[ADVANCED_UPLOADER].treatment === 'on';

  return (
    <div className="flex gap-2">
      <Button
        className="w-30"
        size="s"
        onClick={startFolderNodeCreation}
        active={isFolderNodeCreationStateActive}
        disabled={!userHasEditPermission}
        data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CREATE_FOLDER}
      >
        Create folder
      </Button>

      {isAdvancedUploaderEnabled ? (
        <Upload disabled={!userHasEditPermission} />
      ) : (
        <QueryClientProvider client={queryClient}>
          <RequestContextProvider value={request}>
            <UploadLegacy disabled={!userHasEditPermission} />
          </RequestContextProvider>
        </QueryClientProvider>
      )}
    </div>
  );
};
