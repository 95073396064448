const formatBytes = (bytes: number | null | undefined, decimals = 2) => {
  if (!bytes || bytes === 0)
    return {
      amount: 0,
      unit: 'Bytes',
    };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const indexOfUnit = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    amount: (bytes / k ** indexOfUnit).toFixed(dm),
    unit: units[indexOfUnit],
  };
};

export default formatBytes;
