import { NodeKind, NODE_KIND } from '@/constants/node';
import { queryClient } from '@/graphql/client';
import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import {
  addToServerConfirmedNameChanges,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import { useCallback } from 'react';
import { useFindSystemNodeByIdQuery } from './useFindSystemNodeByIdQuery';
import { useListSystemNodesByParentNodeIdQuery } from './useListSystemNodesByParentNodeIdQuery';
import { useSystemNodesMutation } from './useSystemNodesMutation';
import { extractDisplaySystemNodeName } from '@/utils/systemNode';

export const useHandleUpdateDetails = () => {
  const {
    parentNodeId,
    searchKeyword,
    pagination: { pageIndex, pageSize },
  } = useDataManagementPageStore(state => state);

  const { queryFunction: fetchSystemNodeById } = useFindSystemNodeByIdQuery();
  const handleUpdateNodeNameSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      useListSystemNodesByParentNodeIdQuery.getQueryKey(
        parentNodeId || null,
        pageSize,
        pageIndex,
        searchKeyword,
        [SystemNodeKindInput.FolderNode, SystemNodeKindInput.FileNode],
      ),
    );
  }, [pageIndex, pageSize, parentNodeId, searchKeyword]);

  const { updateSystemNodeName } = useSystemNodesMutation({
    onUpdateSystemNodeNameSuccess: handleUpdateNodeNameSuccess,
  });

  return useCallback(
    async (kind: NodeKind, extension: string, updates: { systemNodeId: string; name: string }) => {
      switch (kind) {
        case NODE_KIND.FILE_NODE: {
          const reconstructedUpdate = {
            systemNodeId: updates.systemNodeId,
            name: extension.length > 0 ? `${updates.name}.${extension}` : updates.name,
          };
          await updateSystemNodeName.mutateAsync(reconstructedUpdate);
          break;
        }
        case NODE_KIND.FOLDER_NODE: {
          await updateSystemNodeName.mutateAsync(updates);
          break;
        }
        default:
          throw new Error('Invalid node to update');
      }

      const { node: updatedSystemNode } = await fetchSystemNodeById(updates.systemNodeId);

      const updatedNodeName = extractDisplaySystemNodeName(kind, updatedSystemNode?.name as string);

      addToServerConfirmedNameChanges(updates.systemNodeId, updatedNodeName);
    },
    [fetchSystemNodeById, updateSystemNodeName],
  );
};
