import { cn } from '@/utils/classname';
import { HTMLAttributes, ReactNode } from 'react';

export interface Props extends HTMLAttributes<HTMLLIElement> {
  className?: string;
  children?: ReactNode;
}

export const SelectItem = ({ className, children, ...props }: Props) => {
  return (
    <li
      className={cn(
        'color-neutral-600',
        'cursor-pointer',
        'flex-nowrap',
        'flex',
        'items-center',
        'justify-between',
        'px-2',
        'py-2px',
        'rounded-2px',
        'typo-text-s',

        'hover:bg-neutral-200',
        'hover:color-neutral-800',
        'hover:typo-text-s-em',

        '[&.disabled]:color-neutral-400',
        '[&.disabled]:hover:bg-transparent',
        '[&.disabled]:hover:color-neutral-400',
        '[&.disabled]:hover:typo-text-s',

        className,
      )}
      {...props}
    >
      {children}
    </li>
  );
};
