import { cn } from '@skand/ui';
import { ChangeEventHandler, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadStore } from '../uploadStore';
import { NodeItem } from '../utils';
import { UploadDetails } from './UploadDetails';
import { UploadOverview } from './UploadOverview';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export interface FileSectionProps {
  addFiles: (files: File[]) => void;
  canAddFiles: boolean;
  fileGraph: NodeItem[];
  stage: 'initial' | 'preparing' | 'prepared' | 'uploading' | 'uploaded';
}

export const FileSection = ({ addFiles, canAddFiles, fileGraph, stage }: FileSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { getRootProps, getInputProps } = useDropzone({ onDrop: addFiles });

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      e.preventDefault();
      const files = [...(e.target.files ?? [])];
      addFiles(files);
      e.target.value = ''; // reset files in dom
    },
    [addFiles],
  );

  const showUploadDetails = useUploadStore(state => state.showUploadDetails);

  return (
    <div
      className={cn(
        'mt-6 b-1 b-neutral-400 rounded-2 b-solid p-3 flex flex-col flex-1  overflow-auto',
      )}
    >
      {fileGraph.length > 0 && <UploadOverview stage={stage} />}

      {fileGraph.length > 0 && showUploadDetails && (
        <UploadDetails fileGraph={fileGraph} stage={stage} />
      )}

      {canAddFiles && (
        <div
          className="b-1 b-dashed b-neutral-400 rounded-2 min-h-80px flex justify-center items-center flex-1"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.DRAG_AND_DROP_FILES}
          {...getRootProps()}
        >
          <span className="typo-text-s color-neutral-500">Drag and drop files or&nbsp;</span>
          <label className="typo-link-s color-neutral-800 underline">
            browse files from your computer
            <input
              type="file"
              ref={inputRef}
              className="hidden"
              onChange={handleChange}
              multiple
              {...getInputProps()}
            />
          </label>
        </div>
      )}
    </div>
  );
};
