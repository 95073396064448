import { UNITS, UploadSessionStage } from '@skand/uploader';

export const PREPARING_STAGES: UploadSessionStage[] = [
  'initial',
  'dataImportRequest_creating',
  'dataImportRequest_creating_success',
  'dataImportRequest_finding',
  'dataImportRequest_finding_success',
  'folderImportRequests_creating',
  'folderImportRequests_creating_success',
  'fileImportRequests_creating',
];

export const PREPARED_STAGES: UploadSessionStage[] = ['fileImportRequests_creating_success'];

export const UPLOADING_STAGES: UploadSessionStage[] = [
  'files_uploading',
  'files_uploading_success',
  'folderNodes_creating',
  'folderNodes_creating_success',
  'fileNodes_creating',
  'thumbnails_creating',

  'files_uploading_retrying',
];

export const UPLOADED_STAGES: UploadSessionStage[] = ['thumbnails_creating_success'];

export const FAILURE_STAGES: UploadSessionStage[] = [
  'dataImportRequest_creating_failure',
  'dataImportRequest_finding_failure',
  'folderImportRequests_creating_failure',
  'fileImportRequests_creating_failure',
  'files_uploading_failure',
  'folderNodes_creating_failure',
  'fileNodes_creating_failure',
  'thumbnails_creating_failure',
];

export const CHUNK_SIZE_OPTION = {
  '5M': 5 * UNITS.BYTE.MB,
  '50M': 50 * UNITS.BYTE.MB,
  '500M': 500 * UNITS.BYTE.MB,
  '5G': 5 * UNITS.BYTE.GB,
} as const;
export type ChunkSizeOption = keyof typeof CHUNK_SIZE_OPTION;

export const DEFAULT_CHUNK_SIZE_OPTION: ChunkSizeOption = '500M';
export const DEFAULT_CHUNK_SIZE = CHUNK_SIZE_OPTION[DEFAULT_CHUNK_SIZE_OPTION];
export const DEFAULT_UPLOAD_SESSION_OPTIONS = {
  pauseBeforeUploadFiles: true,
  rateLimiterOptions: { maxConcurrency: 5 },
};

export const CAN_CHANGE_OPTIONS_STAGES: UploadSessionStage[] = [
  ...PREPARING_STAGES,
  ...PREPARED_STAGES,
];
export const CAN_SAVE_TO_PROJECT_STAGES: UploadSessionStage[] = [
  ...PREPARING_STAGES,
  ...PREPARED_STAGES,
  ...UPLOADING_STAGES,
];
