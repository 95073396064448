import { TableDense } from '@/components/TableDense';
import { cn } from '@/utils/classname';
import formatBytes from '@/utils/formatBytes';
import { Progress } from '@skand/ui';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { NodeItem } from '../utils';

const columnHelper = createColumnHelper<NodeItem>();

export interface UploadDetailsProps {
  fileGraph: NodeItem[];
  stage: 'initial' | 'preparing' | 'prepared' | 'uploading' | 'uploaded';
}

export const UploadDetails = ({ fileGraph, stage }: UploadDetailsProps) => {
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'name',
        cell: ({ row }) => (
          <div className="flex items-center">
            <div
              className="flex-[0_0_auto]"
              style={{ width: `calc(12px * ${row.original.indent})` }}
            />
            <div className={cn(row.original.iconClass)} />
            <div className="ml-2">{row.original.name}</div>
          </div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('type', {
        header: 'file type',
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'status',
        header: 'status',
        cell: ({ row }) => {
          if (row.original.fileId === null) return null;

          const speedMeta = formatBytes(row.original.speed, 1);
          const speed = `${speedMeta.amount} ${speedMeta.unit}/s`;

          return (
            <>
              {row.original.status === 'preparing' && (
                <span className="typo-text-s color-neutral-500">Preparing...</span>
              )}

              {row.original.status === 'prepared' && stage !== 'uploading' && (
                <span className="typo-text-s-em color-success-400">Ready for upload</span>
              )}

              {row.original.status === 'prepared' && stage === 'uploading' && (
                <div className="flex items-center gap-2">
                  <span className="typo-text-s color-neutral-500">Uploading</span>
                  <Progress className="w-80px" progress={row.original.progress} />
                  <span className="typo-text-s color-neutral-500">{speed}</span>
                </div>
              )}

              {row.original.status === 'uploading' && (
                <div className="flex items-center gap-2">
                  <span className="typo-text-s color-neutral-500">Uploading</span>
                  <Progress className="w-80px" progress={row.original.progress} />
                  <span className="typo-text-s color-neutral-500">{speed}</span>
                </div>
              )}

              {row.original.status === 'uploaded' && stage !== 'uploaded' && (
                <div className="flex items-center gap-2">
                  <span className="typo-text-s color-neutral-500">Uploading</span>
                  <Progress className="w-80px" progress={1} />
                  <span className="typo-text-s color-neutral-500">{speed}</span>
                </div>
              )}

              {row.original.status === 'uploaded' && stage === 'uploaded' && (
                <div className="flex items-center gap-2">
                  <span className="typo-text-s color-success-400">Upload complete</span>
                  <span className="typo-text-s color-neutral-500">{speed}</span>
                </div>
              )}

              {row.original.status === 'failed' && (
                <span className="typo-text-s color-alert-400">Upload failed</span>
              )}
            </>
          );
        },
        enableSorting: false,
      }),
    ];
  }, [stage]);

  const table = useReactTable({
    data: fileGraph,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <TableDense table={table} className="mb-3" />;
};
