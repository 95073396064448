import { DatasetState } from './Store';
import { URLStore } from './URL';

/**
 * Persistent storage for Explore state that synchronizes multiple stores.
 */
export class Persist {
  url: URLStore;

  constructor() {
    this.url = new URLStore();
  }

  /**
   * Read a field from the store.
   *
   * @param field
   * @returns
   */
  public get<T extends keyof DatasetState>(field: T): DatasetState[T] | null {
    // URL takes precedence over local storage.
    const result = this.url.get(field);
    return result;
  }

  /**
   * Write a field to store.
   *
   * @param field
   * @param value
   */
  public set<T extends keyof DatasetState>(field: T, value: DatasetState[T]) {
    this.url.set(field, value);
  }

  /**
   * Clear a field from the store.
   *
   * @param field
   */
  public clear<T extends keyof DatasetState>(field: T) {
    this.url.clear(field);
  }
}
