import { setDownloadNoteModal, useDownloadStore } from '@/stores/download';
import { cn } from '@/utils/classname';
import { Button, Input } from '@skand/ui';

const zipFileNameRegex = /^[a-zA-Z0-9_-]*$/;

export const DownloadNoteModal = () => {
  const { downloadNoteModalState } = useDownloadStore();

  const isZipFileNameValid = zipFileNameRegex.test(downloadNoteModalState.zipFileName);

  const handleCloseConfirmationModal = () => {
    setDownloadNoteModal({
      isOpen: false,
      actionFunction: () => {
        return;
      },
      zipFileName: '',
      zipFileNote: '',
    });
  };

  if (!downloadNoteModalState.isOpen) return null;

  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-50 h-full w-full flex items-center justify-center',
        ' bg-black bg-opacity-30',
      )}
    >
      <div
        className={cn(
          'justify-between flex flex-col shadow-lg z-50 border-1 border-neutral-400 rounded-md border-solid bg-white',
          ' w-[438px] p-6 gap-6',
        )}
      >
        <div className="z-20 mt-1 flex flex-col gap-2 ">
          <p className="color-neutral-800 typo-text-m">Add a note</p>
          <p className="color-neutral-600 typo-text-s">
            This message will appear when the download link is opened in a browser
          </p>
        </div>
        <div>
          <Input
            onChange={(e) => {
              setDownloadNoteModal({
                ...downloadNoteModalState,
                zipFileName: e,
              });
            }}
            label="Zip File Name"
            name="text"
            id="text"
            warn={!isZipFileNameValid}
            hint={
              !isZipFileNameValid
                ? 'The file name must contain only alphanumeric characters, underscores, and hyphens.'
                : undefined
            }
            value={downloadNoteModalState.zipFileName}
          />
          <p className="typo-text-xs color-neutral-600 mt-1">Optional</p>
        </div>
        <div className="w-390px h-120px">
          <textarea
            className="resize-none border-neutral-400 w-full h-full rounded p-2 typo-text-s outline-none"
            placeholder="Enter note"
            name=""
            id=""
            value={downloadNoteModalState.zipFileNote}
            onChange={e =>
              setDownloadNoteModal({
                ...downloadNoteModalState,
                zipFileNote: e.target.value,
              })
            }
          />
          <p className="typo-text-xs color-neutral-600">Optional</p>
        </div>
        <div className="w-full flex gap-2 mt-4">
          <Button className="w-full cursor-pointer" onClick={handleCloseConfirmationModal} size="s">
            Cancel
          </Button>
          <Button
            className="w-full cursor-pointer"
            filled
            onClick={() => {
              downloadNoteModalState.actionFunction(
                downloadNoteModalState.zipFileName,
                downloadNoteModalState.zipFileNote,
              );
              handleCloseConfirmationModal();
            }}
            primary
            size="s"
            disabled={!isZipFileNameValid}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};
