/* eslint-disable no-console */
// Logger stubs

export const debug = console.debug;
export const error = console.error;
export const fatal = console.error;
export const info = console.info;
export const trace = console.trace;
export const warn = console.warn;

export const log = info; // alias to info

export default {
  debug,
  error,
  fatal,
  info,
  trace,
  warn,
};
