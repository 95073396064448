import axios from 'axios';

export class DownloadPart {
  url: string;
  range: number[];
  loaded = 0;
  onProgress: (loaded: number) => void;
  abortSignal: AbortSignal;
  blob: Blob | null = null;

  constructor(
    url: string,
    range: number[],
    onProgress: (loaded: number) => void,
    abortSignal: AbortSignal,
  ) {
    this.url = url;
    this.range = range;
    this.onProgress = onProgress;
    this.abortSignal = abortSignal;
  }

  updateAbortSignal(abortSignal: AbortSignal) {
    this.abortSignal = abortSignal;
  }

  async download() {
    if (this.blob !== null) {
      return this.blob;
    }

    this.blob = await axios
      .get(this.url, {
        responseType: 'blob',
        headers: {
          Range: `bytes=${this.range[0]}-${this.range[1]}`,
        },
        onDownloadProgress: progressEvent => {
          this.loaded = progressEvent.loaded;
          this.onProgress(this.loaded);
        },
        signal: this.abortSignal,
      })
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        return blob;
      })
      .catch(reason => {
        this.loaded = 0;
        this.onProgress(this.loaded);

        if (axios.isCancel(reason)) {
          return null;
        }

        throw reason;
      });

    return this.blob;
  }
}
