import { GET_IMAGE_FILE_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (fileId?: null | string) => ['GET_IMAGE_FILE_BY_ID', fileId];

export const useImageFile = (fileId: null | string) => {
  const response = useQuery({
    enabled: !!fileId,
    queryFn: () => request(GET_IMAGE_FILE_BY_ID, { fileIds: [fileId] }),
    queryKey: getQueryKey(fileId),
  });

  const file = useMemo(() => {
    const file = response.data?.filesByIds?.at(0);
    return file?.__typename === 'ImageFile' || file?.__typename === 'AssetFile' ? file : undefined;
  }, [response.data]);

  return { response, file };
};

useImageFile.getQueryKey = getQueryKey;
