import { Select, SelectItem } from '@skand/ui';
import { ChunkOptions } from './utils';

const CHUNK_OPTIONS: SelectItem<ChunkOptions>[] = [
  { key: 'auto', name: 'auto' },
  { key: '5M', name: '5 MB' },
  { key: '50M', name: '50 MB' },
  { key: '500M', name: '500 MB (default)' },
  { key: '5G', name: '5 GB' },
];

export const OptionSection = ({
  chunkSize,
  setChunkSize,
  isDisabled,
}: {
  chunkSize: ChunkOptions;
  setChunkSize: (size: ChunkOptions) => void;
  isDisabled: boolean;
}) => {
  return (
    <div className="mt-3 b-1 b-solid b-neutral-400 rounded-2 p-3">
      <div className="flex justify-between items-center">
        <p className="typo-text-s mr-1 color-neutral-500">
          Split files into small chunks for uploading, set the maximum size of each chunk to be:
        </p>
        <Select
          value={chunkSize}
          onChange={setChunkSize}
          placeholder="chunk size"
          width="full"
          className="w-144px"
          options={CHUNK_OPTIONS}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
