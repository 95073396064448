import { FIND_SYSTEM_NODE_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (nodeId: string) => ['FIND_SYSTEM_NODE_BY_ID', nodeId];

export const useFindSystemNodeByIdQuery = (nodeId?: string) => {
  const queryFunction = async (id: string) => request(FIND_SYSTEM_NODE_BY_ID, { nodeId: id });

  const response = useQuery({
    enabled: !!nodeId,
    queryFn: () => queryFunction(nodeId as string),
    queryKey: getQueryKey(nodeId as string),
    keepPreviousData: true,
  });

  const systemNode = useMemo(() => {
    return response.data?.node;
  }, [response.data?.node]);

  return {
    response,
    systemNode,
    queryFunction,
  };
};

useFindSystemNodeByIdQuery.getQueryKey = getQueryKey;
