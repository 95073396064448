import { SHARE_LINK_TOKEN_HEADER } from '@/constants/misc';
import { getAuth } from './auth';
import { getShareLinkToken } from './shareLink';

export const getHeaders = () => {
  const auth = getAuth();
  const shareLinkToken = getShareLinkToken();
  const headers: Record<string, string> = {};

  if (auth) headers.authorization = auth;
  if (shareLinkToken) headers[SHARE_LINK_TOKEN_HEADER] = shareLinkToken;

  return headers;
};
