import {
  setUserSystemNodesActionsPermissions,
  setParentNodeHighestActionType,
} from '@/stores/userSystemNodesActionsPermissions';
import { useEffect, useMemo } from 'react';
import { useContextUserSystemNodesActionPermissions } from './useContextUserSystemNodesActionPermissions';
import { useFindSystemNodeMostPermissiveActionTypeBySystemNodeId } from './useFindSystemNodeMostPermissiveActionTypeBySystemNodeId';
import { useDataManagementPageStore } from '@/stores/dataManagementStore';
import { hasId } from '@/utils/misc';
import { FoldersPageTableData } from '@/pages/FoldersPages/FoldersPage/useFoldersPageColumns';

const useSetPermissionsAndActionType = (paginatedSystemNodes: FoldersPageTableData[]) => {
  const { parentNodeId } = useDataManagementPageStore(state => state);

  const systemNodeIds = useMemo(
    () =>
      paginatedSystemNodes.reduce((acc, currNode) => {
        if (hasId(currNode)) {
          acc.push(currNode.id as string);
        }
        return acc;
      }, [] as string[]),
    [paginatedSystemNodes],
  );

  const userActionsPermissions = useContextUserSystemNodesActionPermissions({
    objectIds: systemNodeIds,
  });

  const { actionType: parentNodeHighestActionType } =
    useFindSystemNodeMostPermissiveActionTypeBySystemNodeId(parentNodeId);

  useEffect(() => {
    setUserSystemNodesActionsPermissions(userActionsPermissions);
  }, [userActionsPermissions]);

  useEffect(() => {
    setParentNodeHighestActionType({ parentNodeHighestActionType });
  }, [parentNodeHighestActionType]);
};

export default useSetPermissionsAndActionType;
