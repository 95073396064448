import { Download } from './Download';
import { DownloaderError, EventArgsMap, EventCallback, EventCallbackMap, EventName } from './types';

export class Downloader {
  downloads = new Map<string, Download>();
  callbacks: Map<EventName, Set<EventCallback>> = new Map();

  async add(
    url: string,
    size: number,
    options?: {
      connections?: number;
      partSize?: number;
    },
  ) {
    if (!this.downloads.has(url)) {
      const onComplete = (blob: Blob) => {
        this.emit('complete', url, blob);
        this.emit(`complete:${url}`, blob);
      };

      const onError = (error: DownloaderError) => {
        this.emit('error', url, error);
        this.emit(`error:${url}`, error);
      };

      const onProgress = (progress: number) => {
        this.emit('progress', url, progress);
        this.emit(`progress:${url}`, progress);
      };

      const download = new Download(url, size, {
        ...options,
        onComplete,
        onError,
        onProgress,
      });

      this.downloads.set(url, download);
      this.emit('add', url);
    }
  }

  has(url: string) {
    return this.downloads.has(url);
  }

  start(url: string) {
    if (!this.downloads.has(url)) {
      throw new Error(`URL not found: ${url}`);
    }

    const download = this.downloads.get(url) as Download;
    download.start();
    this.emit('start', url);
  }

  stop(url: string) {
    if (!this.downloads.has(url)) {
      throw new Error(`URL not found: ${url}`);
    }

    const download = this.downloads.get(url) as Download;
    download.stop();
    this.emit('stop', url);
  }

  cancel(url: string) {
    if (!this.downloads.has(url)) {
      throw new Error(`URL not found: ${url}`);
    }

    const download = this.downloads.get(url) as Download;
    download.stop();
    this.downloads.delete(url);
    this.callbacks.delete(`complete:${url}`);
    this.callbacks.delete(`error:${url}`);
    this.callbacks.delete(`progress:${url}`);
    this.emit('cancel', url);
  }

  emit<E extends EventName>(event: E, ...args: EventArgsMap[E]) {
    if (this.callbacks.has(event)) {
      const callbacks = this.callbacks.get(event) as Set<EventCallbackMap[E]>;
      for (const callback of callbacks) {
        callback(...args);
      }
    }
  }

  on<E extends EventName>(event: E, callback: EventCallbackMap[E]) {
    if (!this.callbacks.has(event)) {
      this.callbacks.set(event, new Set());
    }

    const callbacks = this.callbacks.get(event) as Set<EventCallbackMap[E]>;
    callbacks.add(callback);
  }

  off<E extends EventName>(event: E, callback: EventCallbackMap[E]) {
    if (this.callbacks.has(event)) {
      const callbacks = this.callbacks.get(event) as Set<EventCallbackMap[E]>;
      callbacks.delete(callback);
    }
  }

  clear<E extends EventName>(event: E) {
    if (this.callbacks.has(event)) {
      const callbacks = this.callbacks.get(event) as Set<EventCallbackMap[E]>;
      callbacks.clear();
      this.callbacks.delete(event);
    }
  }
}
