import { DownloadItem, cancelDownload, retryDownload } from '@/stores/download';
import { Progress } from '@skand/ui';

export interface DownloadItemRowProps {
  downloadItem: DownloadItem;
}

export const DownloadItemRow = ({ downloadItem }: DownloadItemRowProps) => {
  const { progress, name, error, url } = downloadItem;
  const percent = progress * 100;

  return (
    <>
      <p
        className="color-neutral-800 typo-text-s overflow-hidden whitespace-nowrap text-ellipsis col-span-3"
        title={name}
      >
        {name}
      </p>

      <Progress progress={progress} className="col-span-2" />

      <div className=" justify-self-end col-span-1 items-center justify-end flex w-full gap-2">
        {!!error && (
          <div
            className="i-skand-undo cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
            onClick={() => retryDownload(url)}
          />
        )}

        <div
          className="i-skand-closesmall cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
          onClick={() => cancelDownload(url)}
        />

        <p className="color-neutral-500 typo-text-s">{Math.trunc(percent)}%</p>
      </div>
    </>
  );
};
