import { CircularLoadingIndicator } from '@/components/CircularLoadingIndicator';
import { EmptyResourceMessage } from '@/components/EmptyResourceMessage';
import { TableDataPlaceholder } from '@/components/TableDataPlaceholder';
import { DATASETS_FOLDERS } from '@/constants/paths';
import { useDataManagementPageStore } from '@/stores/dataManagementStore';
import { setIsUploadModalOpen } from '@/stores/download';
import { useRecentlyCreatedFolderNodeStore } from '@/stores/recentlyCreatedFolderNodesStore';
import { cn } from '@skand/ui';
import { flexRender } from '@tanstack/react-table';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from './Breadcrumb';
import { CreateFolderRow } from './CreateFolderRow';
import { TableType } from './DataManagementPage';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

interface SystemNodesDisplayTableProps {
  table: TableType;
  isFetchingSystemNodes: boolean;
  totalNumberOfFiles: number;
}

export const SystemNodesDisplayTable = ({
  table,
  isFetchingSystemNodes,
  totalNumberOfFiles,
}: SystemNodesDisplayTableProps) => {
  const history = useHistory();

  const { isFolderNodeCreationStateActive } = useRecentlyCreatedFolderNodeStore(state => state);
  const { editingSystemNodeId, searchKeyword, parentNodeId } = useDataManagementPageStore(
    state => state,
  );
  const isFetching = isFetchingSystemNodes;
  const isEmpty = table.getRowModel().rows.length === 0;
  const isSearching = searchKeyword.length > 0;

  const onNavigate = useCallback(
    (folderNodeId?: string) => {
      if (!folderNodeId) return history.push({ pathname: DATASETS_FOLDERS, search: window.location.search });
      history.push({ pathname: `${DATASETS_FOLDERS}/${folderNodeId}/files`, search: window.location.search });
    },
    [history],
  );

  return (
    <>
      <div className="flex gap-2 flex-none items-center">
        <Breadcrumbs
          parentNodeId={parentNodeId ?? ''}
          total={totalNumberOfFiles}
          isFetching={isFetchingSystemNodes}
          onNavigate={onNavigate}
        />
        {isFetchingSystemNodes && <CircularLoadingIndicator />}
      </div>
      <div className="mt-3 overflow-hidden flex-1 b-solid b-neutral-200 b-4 rounded-tl-2 rounded-tr-2 flex flex-col">
        <table className="contents">
          <thead className="contents">
            {table.getHeaderGroups().map(headerGroup => {
              return (
                <tr
                  className={cn(
                    'pr-4 px-2',
                    'grid grid-cols-5 gap-x-2',
                    'bg-neutral-200',
                    'px-3',
                    'flex-none',
                  )}
                  key={headerGroup.id}
                >
                  {headerGroup.headers.map(header => {
                    const isSortable = header.column.getCanSort();
                    const sort = header.column.getIsSorted();

                    return (
                      <th
                        className={cn('py-3 text-left uppercase color-neutral-800 typo-button-xs', {
                          'hover:cursor-pointer': isSortable,
                          'col-span-2 ': header.id === 'name',
                        })}
                        key={header.id}
                      >
                        <div className="flex items-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}

                          {isSortable && (
                            <div
                              onClick={header.column.getToggleSortingHandler()}
                              className={cn(
                                'i-skand-dropdown ml-2',
                                'hover:cursor-pointer',
                                sort === false ? 'color-neutral-400' : 'color-neutral-800',
                                sort === 'asc' && 'rotate-180',
                              )}
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <div className="flex-1 overflow-auto scrollbar scrollbar-rounded my-1">
            <tbody className={cn('grid auto-rows-max')}>
              {isFetching ? (
                <TableDataPlaceholder isFetching />
              ) : (
                <>
                  {isEmpty && !isFolderNodeCreationStateActive && (
                    <div className="min-h-240px">
                      <EmptyResourceMessage
                        emptyResourceContent={{
                          actionButton: () => setIsUploadModalOpen(true),
                          emptyDesc: 'Use the Upload button to import new data.',
                          emptyTitle: isSearching ? 'No search results found' : 'No data available',
                          buttonLabel: 'Upload',
                          dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.INITIATE_UPLOAD,
                          emptyToast:
                            'Check with your Admin for your permissions if content is expected here.',
                        }}
                      />
                    </div>
                  )}

                  <CreateFolderRow />

                  {table.getRowModel().rows.map(row => {
                    return (
                      <tr
                        className={cn(
                          'h-10',
                          'px-2',
                          'grid grid-cols-5 gap-x-2',
                          {
                            'bg-neutral-200':
                              (row as { original: { id: string } }).original.id ===
                              editingSystemNodeId,
                            'col-span-2 bg-red': row.id === 'name',
                          },
                          'hover:bg-neutral-50',
                        )}
                        key={row.id}
                      >
                        {row.getVisibleCells().map(cell => (
                          <td
                            className={cn('flex items-center color-neutral-800 overflow-hidden', {
                              'col-span-2': cell.column.id === 'name',
                            })}
                            key={cell.id}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </div>
        </table>
      </div>
    </>
  );
};
