import { SearchOption } from '@/constants/node';
import { SystemNodeKindInput } from '@/graphql/codegen/graphql';
import { FoldersPageTableData } from '@/pages/FoldersPages/FoldersPage/useFoldersPageColumns';
import { useDataManagementPageStore } from '@/stores/dataManagementStore';
import { useMemo } from 'react';
import { useFetchSystemNodes } from './useFetchSystemNodes';
import { usePagination } from './usePagination';
import { useRecentlyCreatedFolderNodeStore } from '@/stores/recentlyCreatedFolderNodesStore';
import { useManageSystemNodesSideEffects } from './useManageSystemNodesSideEffects';
import { useTransformAndFilterSystemNodes } from './useTransformAndFilterSystemNodes';

export const useFetchAndManageSystemNodes = () => {
  const { recentlyCreatedFolderNodes } = useRecentlyCreatedFolderNodeStore(state => state);

  const {
    parentNodeId,
    pagination: { pageSize, pageIndex },
    searchKeyword,
    searchOption,
  } = useDataManagementPageStore(state => state);

  const {
    systemNodes: fetchedSystemNodes,
    totalNumberOfFiles,
    isFetchingSystemNodes,
    refetchSystemNodes,
  } = useFetchSystemNodes(
    {
      parentNodeId: parentNodeId || null,
      pageSize,
      pageIndex,
      searchTerm: searchKeyword || undefined,
      nodeKinds: [SystemNodeKindInput.FolderNode, SystemNodeKindInput.FileNode],
    },
    { keepPreviousData: true, enableRecursiveSearch: searchOption === SearchOption.RECURSIVE },
  );

  // code below will triger only when a folder is created or anything is deleted/renamed
  const { transformedAndFilteredSystemNodes: transformedNewlyCreatedFolderNodes } =
    useTransformAndFilterSystemNodes(recentlyCreatedFolderNodes);

  // code below will run whenever a node is deleted, renamed or moved but not when a new folder is created
  const { transformedAndFilteredSystemNodes: transformedAndFilteredFetchedSystemNodes } =
    useTransformAndFilterSystemNodes(fetchedSystemNodes);

  const { elementsForCurrentPage: paginatedSystemNodes, totalNumberOfPages } =
    usePagination<FoldersPageTableData>({
      elements: transformedAndFilteredFetchedSystemNodes,
      totalNumberOfElements: totalNumberOfFiles,
      currentPageIndex: pageIndex,
      pageSize,
    });

  const displaySystemNodes = useMemo(() => {
    return [...transformedNewlyCreatedFolderNodes, ...paginatedSystemNodes];
  }, [paginatedSystemNodes, transformedNewlyCreatedFolderNodes]);

  useManageSystemNodesSideEffects({ displaySystemNodes, refetchSystemNodes });

  return {
    totalNumberOfPages,
    isFetchingSystemNodes,
    totalNumberOfFiles,
    displaySystemNodes,
    refetchSystemNodes,
  };
};
